@import "ui/styles/2-tools/tools.media-query.scss";

.ModalTabContent_content {
	display: block;
	font-size: var(--font-size--sm);
	padding-bottom: var(--spacing--md);

	@include mq("sm") {
		display: none;
		padding-bottom: var(--spacing--2xl);
	}

	&.isActive {
		display: block;
	}
}

.ModalTabContent_country {
	font-weight: var(--font-weight--semibold);
}

.ModalTabContent_language {
	text-decoration: underline;
}

.ModalTabContent_listItem {
	list-style: none;
}
