@import "ui/styles/2-tools/tools.media-query.scss";

.EmailQuote {
	padding: var(--spacing--xl) 0;

	@include mq("md") {
		display: flex;
		justify-content: space-between;
	}
}

.EmailQuote_button {
	font-size: var(--font-size--md);

	@include mq("md") {
		font-size: var(--font-size--sm);
	}

	&___noIcon {
		width: 100%;

		@include mq("md") {
			width: initial;
		}
	}

	&___withIcon {
		margin-bottom: var(--spacing--sm);
		padding: var(--spacing--sm) var(--spacing--lg) var(--spacing--sm) 0;

		@include mq("md") {
			margin-bottom: 0;
		}
	}
}

.EmailQuote_arrowIcon {
	margin-right: var(--spacing--sm);
}
