@import "ui/styles/2-tools/tools.aspect-ratio.scss";

.ArticleMedia {
	margin-top: var(--spacing--md);
	margin-bottom: var(--spacing--md);
	width: 100%;
}

.ArticleMedia_caption {
	margin-top: var(--spacing--xs);
	font-style: italic;
	font-size: var(--font-size--sm);
	text-align: center;
	padding: 0 var(--spacing--md);
}

.ArticleMedia_mediabox {
	@include aspect-ratio(5, 2);
}
