@import "ui/styles/2-tools/tools.action.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Link {
	@include action;
	&___primary{
		font-size: var(--font-size--md);
		text-decoration: underline;
	}
	&___secondary {
		font-size: var(--font-size--md);
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
	&___sm {
		font-size: var(--font-size--sm);
	}
	&___underline{
		@include text-link--underline();
	}
	&___chevron {
		display: flex;
	}
}

.Link_chevron {
	height: 1.3em;
	width: auto;
	position: relative;
    top: 0.07em;
}