.AddressCard {
	&___primary {
		color: var(--color-primary-dark-blue);
	}

	&___secondary {
		color: var(--color-primary-gray-90);
	}
}

.AddressCard_heading {
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	margin-bottom: 1rem;
}
