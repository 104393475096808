.Accordion {
	background-color: var(--color-light);
	margin: 0;
	padding: 0;
	border: var(--border--md) solid var(--color-border--gray);
	border-radius: var(--corner-size--sm);
	width: 100%;
	margin-bottom: var(--spacing--5xl);
}

.Accordion_ref {
	position: absolute;
	transform: translateY(-300px);
}

.Accordion_heading {
	margin-bottom: var(--spacing--md);
}
