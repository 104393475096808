/* ------------------------------------ *\
		elements.address
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

address {
	font-style: normal;
}
