.HeroCard {
	position: relative;
	height: 100%;
	min-height: 400px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2em;

	&___min {
		min-height: 300px;
	}
}

.HeroCard_heading {
	margin-bottom: 0.5em;
}

.HeroCard_textbox {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: var(--spacing--xl);
	max-width: 1024px;
	margin: auto;
}

.HeroCard_mediabox {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.HeroCard_kicker {
	position: relative;
	top: unset;
	left: unset;
	text-transform: uppercase;
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--semibold);
	margin-bottom: 1em;
	color: var(--color-light);
}

.HeroCard_link {
	display: block;
	color: inherit;
	text-decoration: inherit;
}

.HeroCard_action {
	margin: auto;
}
