@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormfieldTextarea {
	position: relative;
	display: flex;
	flex-flow: column;
	color: var(--color-primary-gray-90);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-primary-gray-5);
	}
}

.FormfieldTextarea_input {
	@include input;
	line-height: var(--line-height--md);
	margin: var(--spacing--2xs) 0;
	height: 12rem;
	font-size: var(--font-size--sm);
	resize: none;

	.isDisabled & {
		cursor: not-allowed;
	}
}

.FormfieldTextarea_label {
	@include formfield-label;
}

.FormfieldTextarea_input:not([disabled]):hover,
.FormfieldTextarea_label:hover ~ .FormfieldTextarea_input:not([disabled]),
.FormfieldTextarea_input:focus {
	border-color: var(--color-tertiary);
}
