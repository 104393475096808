.ArticleAuthor {
	margin-top: var(--spacing--xl);
	padding-top: var(--spacing--xl);
	margin-bottom: var(--spacing--xl);
	border-top: var(--border--sm) solid var(--color-primary-gray-5);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--2xl);
}

.ArticleAuthor_title {
	color: var(--color-primary-dark-blue);
	text-transform: uppercase;
}

.ArticleAuthor_link {
	color: var(--color-primary-dark-blue);
}

.ArticleAuthor_bio {
	color: var(--color-primary-dark-blue);
	margin-top: 1em;
}
