@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.MediaStory {
	margin-bottom: var(--spacing--5xl);
}

.MediaStory_heading {
	position: relative;
	margin-bottom: var(--spacing--lg);
	cursor: pointer;

	@include mq("sm") {
		cursor: initial;
	}
}

.MediaStory_buttonIcon {
	width: 1rem;
	height: 1rem;
	position: absolute;
	right: 1.5rem;
	top: 1rem;

	@include transition {
		transition: all var(--trans-time--long);
	}
	.isClosed & {
		transform: rotate(180deg);
	}

	@include mq("sm") {
		display: none;
	}
}

.MediaStory_grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: var(--spacing--md);
	border: var(--border--lg) solid var(--color-border--subtle);
	align-items: center;

	@include mq("sm") {
		grid-template-columns: repeat(2, 1fr);
		gap: var(--spacing--lg);

		:nth-child(2) {
			grid-row: 1;
		}
	}

	@include transition {
		transition: all var(--trans-time--long);
	}

	&.isClosed {
		display: none;
	}
}

.MediaStory_video {
	padding: 0;

	@include mq("sm") {
		padding: var(--spacing--lg);
	}
}

.MediaStory_content {
	padding: var(--spacing--md);

	@include mq("sm") {
		padding: var(--spacing--lg);
	}
}

.MediaStory_subHeading {
	margin-bottom: var(--spacing--md);
}

.MediaStory_lead {
	margin-bottom: var(--spacing--lg);
	font-size: var(--font-size--sm);
}

.MediaStory_button {
	margin-bottom: var(--spacing--md);
	width: 100%;

	@include mq("sm") {
		width: initial;
	}
}
