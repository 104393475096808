/* ------------------------------------ *\
		elements.page
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

html {
	font-family: var(--font-primary);
	font-size: 10px;
}

body {
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
	color: var(--color-text--primary);
}
