@import "ui/styles/2-tools/tools.media-query.scss";

.ModalForm_submitButtonContainer {
	order: 11;
	flex: 0 0 100%;
	margin-top: var(--spacing--md);

	@include mq("md") {
		margin-top: var(--spacing--sm);
		margin-left: auto;
	}

	button {
		width: 100%;
	}
}
