@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.Modal {
	z-index: var(--z-index--modal);
	position: fixed;
	width: 100vw;
	max-width: 100%;
	height: 100vh;
	align-items: center;
	justify-content: center;
	visibility: visible;
	display: flex;
	top: 0;
	left: 0;
	transition-delay: unset;
	padding: var(--spacing--md);
}

.Modal_overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--shadow-color--mild);
	opacity: 1;

	@include transition {
		transition: var(--trans-time--standard) opacity ease;
	}
}

.Modal_modal {
	position: relative;
	width: 100%;
	height: 100%;

	overflow-y: auto;
	z-index: 1;
	transform: scale(1);
	opacity: 1;
	padding: var(--spacing--md);
	box-shadow: 0.1em 0.1em 0.2em var(--shadow-color--mild);
	text-align: left;
	overflow-x: hidden;

	@include transition {
		transition: var(--trans-time--shorter) transform ease,
			var(--trans-time--shorter) opacity ease;
	}

	@include mq("md") {
		background-color: var(--color-white);
		width: 80vw;
		min-height: 60vh;
		padding: var(--spacing--3xl);
	}

	&.Modal___languageSelectorModal {
		background-color: var(--color-supporting-sky-blue-20);

		@include mq("md") {
			background-color: var(--color-white);
		}
	}
	&.Modal___formModal {
		max-width: 640px;
		background-color: var(--color-white);

		@include mq("sm") {
			width: 80vw;
		}

		@include mq("lg") {
			width: 60vw;
		}
	}

	&.Modal___productDetailsModal {
		background-color: var(--color-white);
		width: 100vw;
	}
}

.Modal_icon {
	width: 15rem;
	margin-bottom: var(--spacing--md);
	visibility: hidden;

	&.Modal___languageSelectorModal {
		@include mq("md") {
			visibility: visible;
		}
	}
	&.Modal___formModal {
		visibility: visible;
	}

	&.Modal___productDetailsModal {
		display: none;
	}
}

.Modal_header {
	text-transform: uppercase;
	padding-bottom: var(--spacing--sm);
	color: var(--color-primary-dark-blue);

	@include mq("md") {
		text-align: left;
		display: block;
	}

	&.Modal___languageSelectorModal {
		text-align: center;

		@include mq("md") {
			text-align: left;
		}
	}
	&.Modal___formModal {
		text-align: left;
	}
}

.Modal_accept {
	margin-top: var(--spacing--xl);
}

.Modal_close {
	position: absolute;
	outline: none;
	right: var(--spacing--base);
	top: var(--spacing--base);
	cursor: pointer;
	padding: 0;
	border: 0;
	z-index: 2;
	background-color: transparent;

	@include mq("md") {
		right: var(--spacing--lg);
		top: var(--spacing--lg);
	}

	&.Modal___languageSelectorModal {
		background-color: var(--color-supporting-sky-blue-20);

		@include mq("md") {
			background-color: var(--color-white);
		}
	}

	&.Modal___formModal {
		background-color: var(--color-white);
	}

	&.Modal___productDetailsModal {
		background-color: var(--color-white);
	}
}

.Modal_closeIcon {
	width: 4rem;
	height: 4rem;

	@include mq("sm") {
		width: 5rem;
		height: 5rem;
	}
}

.Modal_TabMenu {
	margin-top: var(--spacing--lg);
}

.Modal_tabMenuContainer {
	display: block;
	position: relative;

	@include mq("sm") {
		display: flex;
	}
}

.Modal_tabMenuBorder {
	display: none;
	bottom: 0;
	width: 100%;
	position: absolute;
	border-bottom: var(--border--sm) solid var(--color-border--subtle);

	@include mq("sm") {
		display: block;
	}
}

.Modal_tabMenuContentContainer {
	padding-top: var(--spacing--lg);
	flex-wrap: wrap;
}
