.ShoppingCartItem {
	margin: var(--spacing--sm) 0;
	padding-bottom: var(--spacing--sm);
	border-bottom: var(--border--sm) solid var(--color-primary-gray-5);
	display: flex;
	&:last-child {
		margin-bottom: 0;
	}
}

.ShoppingCartItem_image {
	max-width: 10rem;
	margin-right: var(--grid-gutter);
}

.ShoppingCartItem_info {
	width: 100%;
}

.ShoppingCartItem_section {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ShoppingCartItem_title {
	color: currentColor;
	font-size: var(--font-size--lg);
	font-weight: var(--font-weight--bold);
}

.ShoppingCartItem_deleteButton {
	padding: 0;
	background: none;
	border: none;
	color: var(--color-black);
}

.ShoppingCartItem_quantity {
	display: flex;
	align-items: center;
	margin: var(--grid-gutter) 0;
}

.ShoppingCartItem_quantityButton {
	font-size: var(--font-size--xl);
	width: 3rem;
	height: 3rem;
	border-radius: var(--corner-circle);
	border: none;
	margin: 0 var(--spacing--xs);
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
}
