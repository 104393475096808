@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.Header {
	position: sticky;
	top: 0;
	font-size: var(--font-size--sm);
	color: var(--color-light);
	background: var(--color-white);
	z-index: var(--z-index--header);
	will-change: transform, background-color, opacity, margin;

	@include mq("md") {
		border-bottom: var(--border--sm) solid var(--color-border--subtle);
	}
}

.Header_identity {
	display: none;

	@include mq("md") {
		display: block;
		width: 140px;
		height: 55px;
		max-width: 100%;
		flex: none;
	}

	&___mobile {
		width: 100px;
		height: 38px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		@include mq("md") {
			display: none;
		}
	}
}

.Header_topContainer {
	position: relative;
}

.Header_top {
	display: none;

	@include mq("md") {
		display: flex;
		justify-content: space-between;
		background-color: var(--color-white);
		padding: var(--spacing--xl) 0;
		align-items: center;
	}
}

.Header_service {
	display: none;

	@include mq("md") {
		display: flex;
	}
}

.Header_bottom {
	@include mq("md") {
		background-color: var(--color-primary-gray-5);
		border-top: var(--border--sm) solid var(--color-primary-sand);
		border-bottom: var(--border--sm) solid var(--color-primary-sand);
	}
}

.Header_bottomContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: var(--spacing--lg);
	padding-bottom: var(--spacing--md);

	@include mq("md") {
		padding-bottom: var(--spacing--lg);
	}
}

.Header_nav {
	display: none;

	@include mq("md") {
		display: block;
	}
}

.Header_navToggle {
	flex: 0 0 auto;
	margin-right: var(--spacing--md);
	z-index: 2;
	width: var(--spacing--lg);
	display: block;
	cursor: pointer;
	transition: 0.2s ease-in-out;

	@include mq("md") {
		display: none;
	}
}

.Header_navigation {
	display: flex;
	align-items: center;
	@include mq("md") {
		width: initial;
		height: initial;
	}
}

.Header_overlay {
	display: none;
	width: 100vw;
	height: 100vh;
	background: var(--shadow-color--basemild);
	position: absolute;
	z-index: 1;

	&.isActive {
		display: block;
	}
}

.Header_navToggle {
	cursor: pointer;
	width: 24px;
	margin-right: var(--spacing--md);

	&.isActive {
		right: 0;
		position: absolute;
		z-index: 2;

		.Header_iconBar {
			&:nth-child(1) {
				transform: rotate(45deg) translateY(100%);
			}
			&:nth-child(2) {
				opacity: 0;
				transform: translateY(-7px);
			}
			&:nth-child(3) {
				transform: rotate(-45deg) translateY(-100%);
			}
		}
	}

	@include mq("md") {
		display: none;
	}
}

.Header_iconBar {
	display: block;
	width: 100%;
	height: 1px;
	background: currentColor;
	@include transition {
		transition: all var(--trans-time--standard) var(--ease--dynamic);
	}

	&:first-child {
		transform: translateY(-7px);
	}

	&:nth-child(3) {
		transform: translateY(7px);
	}
}

.Header_avatarIcon {
	flex: 0 0 auto;
	cursor: pointer;

	@include mq("md") {
		display: none;
	}
}

.Header_mobileNav {
	overflow-y: auto;
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	margin: auto;
	padding: 10rem var(--spacing--md);
	background-color: var(--color-white);
	z-index: 1;

	@include mq("sm") {
		padding: 10rem var(--spacing--sm);
	}
}

.Header_mobileAvatar {
	position: absolute;
	width: 100vw;
	min-height: 125px;
	z-index: 3;
	top: 0;
	left: 0;
	margin: auto;
	padding: var(--spacing--md);
	background-color: var(--color-white);
	z-index: 1;

	@include mq("sm") {
		padding: 10rem var(--spacing--sm);
	}
	@include mq("md") {
		display: none;
	}
}

.Header_mobileGlobalNav {
	margin-bottom: var(--spacing--xl);
}
