@import "ui/styles/2-tools/tools.display-mixins";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.Logo {
	text-decoration: none;
	color: currentColor;
}

.Logo_heading {
	@include hidden-absolute;
}

.Logo_asset {
	display: block;
	width: auto;
	height: auto;

	&___isSmall {
		width: 120px;
		padding: var(--spacing--lg) 0;

		@include mq("md") {
			padding: 0;
		}
	}
}
