@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Heading {
	&___xxs {
		@include heading--xxs;
	}

	&___xs {
		@include heading--xs;
	}

	&___sm {
		@include heading--sm;
	}

	&___md {
		@include heading--md;
	}

	&___lg {
		@include heading--lg;
	}

	&___xl {
		@include heading--xl;
	}

	&___textCenter {
		text-align: center;
	}

	&___uppercase {
		text-transform: uppercase;
	}
}
