@import "ui/styles/2-tools/tools.media-query.scss";

.OrderHistoryItem {
	padding: 2rem;
	border: 1px solid var(--color-primary-gray-30);
	border-radius: 4px;

	@include mq("md") {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 2.5rem;
	}

	&_column {
		margin: 0;
		padding: 0;

		&_item {
			margin: 0;
			margin-bottom: 1.2rem;
			padding: 0;
			list-style: none;

			strong {
				display: block;

				@include mq("lg") {
					display: inline;
				}
			}
		}
	}

	&_status {
		&___isActive {
			color: var(--color-green);
		}
	}

	&_buttons {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		margin-top: 3rem;

		@include mq("md") {
			margin-top: 0;
		}
	}

	&_button {
		flex: 0 0 auto;
	}
}
