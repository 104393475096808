@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Breadcrumb {
	list-style: none;
	color: var(--color-primary-dark-blue);
	margin-top: var(--spacing--lg);
	margin-bottom: var(--spacing--3xl);
}

.Breadcrumb_item {
	display: inline-block;

	// Breadcrumb divider
	&:not(:last-child)::after {
		display: inline-block;
		content: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzAwIDMwMCIgaWQ9Ikljb25fRnVuY3Rpb25fQ2hldnJvblJpZ2h0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMjAgMjM2LjlhOC44IDguOCAwIDAxLTUuNS0xLjkgOC45IDguOSAwIDAxLTEuNi0xMi42bDU1LjctNzEuNS01NS44LTczLjRhOSA5IDAgMDExNC40LTEwLjlsNjQuMiA4NC40LTY0LjMgODIuNGE4LjkgOC45IDAgMDEtNy4xIDMuNXoiPjwvcGF0aD48L3N2Zz4=");
		vertical-align: middle;
		width: 1.4rem;
		color: var(--color-primary-gray-50);
	}

	&:last-child {
		margin-right: var(--spacing--xs);
	}

}

.Breadcrumb_url {
	display: inline-block;
	padding: 0 var(--spacing--2xs);
	@include text-link--sm();
	color: var(--color-primary-dark-blue);

	&___current {
		text-decoration: none;
		padding: 0 var(--spacing--2xs);
		color: var(--color-primary-gray-50);
	}
}
