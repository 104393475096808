@import "ui/styles/2-tools/tools.media-query.scss";

.Footer {
	padding: calc(#{var(--spacing--xl)} * 2) 0;
	background-color: var(--color-supporting-sky-blue-20);
}

.Footer_cell {
	display: flex;
	flex-direction: column;
	.Footer_socialLinks {
		display: none;
		@include mq("md") {
			margin: auto 0 0 0;
			padding-bottom: var(--spacing--lg);
			display: flex;
		}
	}
}

.Footer_cellLanguageSelector {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@include mq("md") {
		align-items: flex-end;
	}
}
