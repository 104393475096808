@import "ui/styles/2-tools/tools.media-query.scss";

.InformationForm_inner {
	@include mq("md") {
		width: 60%;
	}
}

.InformationForm_fieldset {
	margin-bottom: 2rem;

	@include mq("md") {
		display: flex;
		gap: 2rem;
		flex-wrap: wrap;
	
	}
}

.InformationForm_field {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;

	@include mq("md") {
		width: calc(50% - 2rem);
	}

	input,
	select {
		margin-top: auto;
	}

	input[type="checkbox"],
	input[type="radio"] {
		margin-top: 0;
	}
}

.InformationForm_checkbox {
	label {
		font-weight: inherit;
	}
}
