/* ------------------------------------ *\
		elements.anchor
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

a {
	// Inherits font family from parent (not default in browsers)
	font-family: inherit;
	color: var(--color-anchor--primary);
	text-decoration: underline;
}
