@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.Pagination {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: var(--spacing--lg);
	margin-bottom: var(--spacing--xl);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--xs);
	font-weight: var(--font-weight--semibold);
	color: var(--color-cta-primary);

	@include mq("md") {
		column-gap: var(--spacing--2xl);
	}

	&___border-top {
		border-top: var(--border--sm) solid var(--color-primary-gray-40);
	}
}

.Pagination_item {
	display: inline-block;

	&___active {
		text-decoration: underline 2px;
		text-underline-offset: var(--spacing--xs);
		
		> button {
			text-decoration: underline 2px;
			text-underline-offset: var(--spacing--xs);
		}
	}

	&___gutter {
		margin: 0 var(--spacing--lg);
	}

	&:first-of-type,
	&:last-of-type {
		display: none;
	}


	// Toggle class used in JS
	&___hide {
		display: none;
	}

	> button {
		min-width: auto;
	}
}

.Pagination_link {
	text-decoration: none;
	font-size: inherit;
	padding: var(--spacing--2xs);
	&:hover {
		opacity: 0.8;
	}
}

.Pagination_link___prev,
.Pagination_link___next {
	font-size: 0;
	@include mq("sm") {
		display: flex;
		align-items: center;
		font-size: inherit;
		font-weight: var(--font-weight--bold);
		color: var(--color-cta-primary);
	}
	&:hover {
		opacity: 0.8;
	}
}

.Pagination_link___hidden {
	opacity: 0;
}

.Pagination_chevron {
	display: inline-block;
	width: var(--icon-size--lg);
	position: relative;
	top: 0.3rem;

	@include mq("sm") {
		display: none;
	}
}
