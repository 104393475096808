/* ------------------------------------ *\
		tools.typography-mixins
\* ------------------------------------ */

@import "ui/styles/2-tools/tools.media-query.scss";

@mixin heading--xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--3xl);
	line-height: var(--line-height--2xs);
	text-transform: uppercase;
	@include mq("md") {
		font-size: var(--font-size--5xl);
		line-height: var(--line-height--lg);
	}
}

@mixin heading--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--2xl);
	line-height: var(--line-height--xs);
	text-transform: uppercase;
	@include mq("md") {
		font-size: var(--font-size--4xl);
		line-height: var(--line-height--lg);
	}
}

@mixin heading--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--2xl);
	line-height: var(--line-height--md);
}

@mixin heading--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--xs);
	letter-spacing: -0.02em;
}

@mixin heading--xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
	letter-spacing: -0.02em;
}

@mixin heading--xxs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--xs);
	line-height: var(--line-height--2xl);
	letter-spacing: 0.05em;
}

@mixin subheading {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--sm);
}

@mixin paragraph--xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--md);
}
@mixin paragraph--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--4xl);
}

@mixin paragraph--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
	line-height: var(--line-height--2xl);
}

@mixin paragraph--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--3xl);
}

@mixin text-link--underline {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xs);
	line-height: var(--line-height--sm);
	color: var(--color-text--primary);
	text-decoration: none;
	padding-bottom: 3px;
	border-bottom: var(--border--sm) solid var(--color-border--subtle);
	@include transition {
		transition: border-bottom var(--trans-time--long);
	}
	&:hover,
	&:focus {
		border-bottom: var(--border--sm) solid var(--border-color--secondary);
		text-decoration: none;
	}
}

@mixin text-link {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	color: var(--color-text--primary);
	text-decoration: underline;
}

@mixin text-link--xs {
	@include text-link();
	font-size: var(--font-size--xs);
	line-height: var(--line-height--sm);
}

@mixin text-link--sm {
	font-size: var(--font-size--sm);
	line-height: var(--line-height--sm);
}

@mixin text-link--md {
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
}

@mixin text-link--lg {
	font-size: var(--font-size--lg);
	line-height: var(--line-height--xl);
}

@mixin text-link--xl {
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--3xl);
	line-height: var(--line-height--xl);
}

@mixin formfield-label {
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--semibold);
	color: currentColor;
	width: 100%;
	cursor: pointer;
	padding-bottom: var(--spacing--sm);

	@include mq("md") {
		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--medium);
	}

	&___small {
		font-size: var(--font-size--sm);

		@include mq("md") {
			font-size: var(--font-size--xs);
		}
	}
}
