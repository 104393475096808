@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";

.ContentCard {
	position: relative;
	height: 100%;
	margin-bottom: calc(var(--grid-gutter) * 2);

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		&___row {
			display: flex;
			flex-direction: row;
		}

		&___rowReverse {
			display: flex;
			flex-direction: row-reverse;
		}
	}

	&___bordered {
		border: var(--border--sm) solid var(--color-primary-gray-5);
	}

	&___padded {
		padding: var(--spacing--md);
	}

	&___padded-x2 {
		padding: var(--spacing--xl);
	}
}

.ContentCard___link {
	display: block;
	text-decoration: none;
	color: var(--color-black);
	padding: var(--spacing--lg);
	border-radius: var(--corner-size--xs);
	box-shadow: 0 0 0.2em var(--shadow-color--mild);
}

.ContentCard___link:hover,
.ContentCard___link:focus {
	background-color: var(--color-primary-gray-5);
}

.ContentCard_textbox {
	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		.ContentCard___row &,
		.ContentCard___rowReverse & {
			width: 50%;
		}
		.ContentCard___row & {
			padding-left: calc(var(--grid-gutter) * 2);
		}

		.ContentCard___rowReverse & {
			padding-right: calc(var(--grid-gutter) * 2);
			text-align: right;
		}
	}
}

.ContentCard_kicker {
	font-size: var(--font-size--sm);
	text-transform: uppercase;
	margin-bottom: 0.5em;
	color: var(--color-primary-dark-blue);
	font-weight: var(--font-weight--bold);
}

.ContentCard_heading {
	margin-bottom: 0.5em;
}

.ContentCard_text p {
	margin-bottom: 1em;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.ContentCard_action {
	margin-top: 1em;
}

.ContentCard_link {
	display: block;
	color: inherit;
	text-decoration: none;
}

.ContentCard_media {
	display: block;
	margin-bottom: 0.5em;

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		.ContentCard___row &,
		.ContentCard___rowReverse & {
			width: 50%;
		}
	}
}

.ContentCard_mediabox {
	@include aspect-ratio(16, 9);
}

.ContentCard_date {
	font-size: var(--font-size--sm);
	margin-bottom: 0.5em;
}

.ContentCard_button {
	margin-top: var(--spacing--base);
}
