@import "ui/styles/2-tools/tools.media-query.scss";

.OrderDetails {
	&_details {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 3rem;

		@include mq("md") {
			flex-direction: row;
		}
	}

	&_columns {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 2rem 4rem;

		@include mq("md") {
			flex-direction: row;
		}

		@include mq("lg") {
			column-gap: 10rem;
		}
	}

	&_column_item {
		margin-bottom: 0.5rem;
	}

	&_button {
		display: inline-flex;
		align-items: center;
		flex: 0 0 auto;
		column-gap: 1rem;

		@include mq("md") {
			margin-left: auto;
		}
	}
}
