@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.ServiceNavigation {
	@include reset-list;

	margin-top: var(--spacing--lg);

	@include mq("md") {
		display: flex;
		margin-top: 0;
	}
}

.ServiceNavigation_item {
	margin-bottom: var(--spacing--md);

	@include mq("md") {
		display: grid;
		align-items: center;
		margin-right: var(--spacing--xl);
		margin-bottom: 0;
	}
}

.ServiceNavigation_link {
	font-size: var(--font-size--md);
	line-height: var(--line-height--md);
	color: var(--color-primary-gray-50);
	@include mq("md") {
		font-size: var(--font-size--xs);
	}
}
