@import "ui/styles/2-tools/tools.media-query.scss";

.ModalProductImages {
	width: 100%;
	height: 100%;
	position: relative;

	@include mq("md") {
		display: flex;
		flex-wrap: initial;
	}
}

.ModalProductImages_imagesContainer {
	width: 100%;
	position: relative;

	@include mq("md") {
		order: 2;
	}
}

.ModalProductImages_images {
	position: absolute;
	visibility: hidden;
	width: 100%;

	@include mq("md") {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 100%;
	}

	&.isActive {
		visibility: visible;
	}
}

.ModalProductImages_paginationContainer {
	position: absolute;
	bottom: 20px;
	width: 100%;
	text-align: center;

	@include mq("md") {
		bottom: initial;
		width: initial;
		position: relative;
		order: 1;
	}
}

.ModalProductImages_pagination {
	display: inline-block;
	width: 64px;
	height: 64px;
	margin: var(--spacing--xs) 0;
	border: 2px solid var(--color-white);
	border-radius: 4px;
	box-sizing: border-box;
	cursor: pointer;

	@include mq("md") {
		display: block;
	}

	&.isActive {
		border-color: var(--color-primary-gray-50);
	}
}
