// @import "ui/styles/2-tools/tools.media-query.scss";

.InformationText {
	display: flex;
	align-items: center;
}

.InformationText_text {
	margin-right: var(--spacing--sm);
	font-size: var(--font-size--xs);
}
