@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.FooterLanguageSelector {
	color: var(--color-primary-dark-blue);
	margin-top: var(--spacing--xl);
	padding-bottom: 0;
	@include mq("md") {
		margin-top: auto;
		padding-bottom: var(--spacing--md);
	}
}

.FooterLanguageSelector_textbox {
	font-size: var(--font-size--sm);
}

.FooterLanguageSelector_textbox button a {
	color: var(--color-light);
}

.FooterLanguageSelector_buttonIcon {
	width: 1rem;
	height: 1rem;
	position: absolute;
	transform: translateY(-50%);
	top: 50%;

	@include transition {
		transition: all var(--trans-time--long);
	}
}

.FooterLanguageSelector_languageSelector {
	cursor: pointer;
	position: relative;
	display: flex;
}

.FooterLanguageSelector_languageSelectorText {
	text-transform: uppercase;
	font-weight: var(--font-weight--semibold);
	padding-right: var(--spacing--xs);
	position: relative;
}
