.ArticleText {
	/**
	* In this molecule we style html elements directly
	* since they come from the RTE of a CMS or another editor
	*/

	margin-top: var(--spacing--xl);
	margin-bottom: var(--spacing--xl);
	font-size: var(--font-size--md);

	p {
		margin-bottom: var(--spacing--md);

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: var(--spacing--xs);
	}

	blockquote {
		margin-top: var(--spacing--xl);
		margin-bottom: var(--spacing--xl);
		margin-left: auto;
		margin-right: auto;
		font-size: var(--font-size--xl);
		font-weight: var(--font-weight--bold);
		line-height: var(--line-height--3xl);
		text-align: center;
		color: var(--color-primary-dark-blue);
		width: 75%;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 3em;
			height: 0;
			margin: 0.5em auto 0.5em auto;
			border-bottom: 1px solid currentColor;
		}
	}

	table {
		border-spacing: 5vw 0;
		margin-left: -5vw;
		margin-right: -5vw;
		margin-top: var(--spacing--xs);
	}

	th {
		text-align: left;
		padding-top: var(--spacing--2xs);
	}
	th,
	td {
		vertical-align: top;
	}
	td {
		font-size: var(--font-size--sm);
	}
	ul,
	ol {
		margin: var(--spacing--base) 0;
		padding-left: 1em;
		line-height: var(--line-height--2xl);
	}
}