@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

$checkbox-height: 1.8rem;

.FormfieldCheckbox {
	display: flex;
	flex-flow: column;
	color: var(--color-primary-gray-90);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-primary-gray-5);
	}
}

.FormfieldCheckbox_fieldContainer {
	position: relative;
}

.FormfieldCheckbox_label {
	display: block;
	cursor: pointer;
	position: relative;
	padding-left: calc(#{$checkbox-height} * 1.5);

	.isDisabled & {
		cursor: not-allowed;
	}

	&:before, &:after {
		content: "";
		display: block;
		position: absolute;
		top: 0.35em;
		left: 0;
	}

	&:before {
		width: $checkbox-height;
		height: $checkbox-height;
		background-color: var(--color-primary-gray-5);
		flex-shrink: 0;
		border: 1px solid var(--color-primary-gray-60);
		border-radius: 2px;

		.hasError & {
			border-color: var(--color-error);
		}
	}

	&:after {
		width: calc(#{$checkbox-height} / 3.2);
		height: calc(#{$checkbox-height} / 2);
		border: solid transparent;
		border-width: 0 var(--border--md) var(--border--md) 0;
		transform: rotate(45deg) translate(110%, -20%);

		.FormfieldCheckbox_input:checked + & {
			border-color: var(--color-primary-dark-blue);
		}
	}
}

.FormfieldCheckbox_input {
	@include input;

	position: absolute;
	top: 0.35em;
	width: $checkbox-height;
	height: $checkbox-height;
	opacity: 0;

	&.hasError {
		color: var(--color-error);
	}
}

.FormfieldCheckbox_input:focus ~ .FormfieldCheckbox_label:before,
.FormfieldCheckbox_input:not([disabled]) ~ .FormfieldCheckbox_label:hover:before {
	border-color: var(--color-tertiary);
}
