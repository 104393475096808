@import "ui/styles/2-tools/tools.typography-mixins.scss";

.RichText {
	h1 {
		@include heading--xl;
	}

	h2 {
		@include heading--lg;
	}

	h3 {
		@include heading--md;
	}

	h4 {
		@include heading--sm;
	}

	h5 {
		@include heading--xs;
	}

	p {
		@include paragraph--md;
	}
}
