// TODO: SHOULD BE REMOVED AND REPLACED BY STYLING IN MODULE

/* ===========================================
	utilities.container
	========================================= */

/**
 *  Utilities – utility- and helper classes
 *  with the ability to override anything
*/

.u-container {
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--base);
}

.u-container--narrow {
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--base);
}

.u-container--slim {
	max-width: 720px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--base);
}

.u-container--skinny {
	max-width: 650px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--base);
}

.u-container--fluid {
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--base);
}
