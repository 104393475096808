@import "ui/styles/2-tools/tools.media-query.scss";

.QuickOrder {
	display: inline-flex;
	flex-wrap: wrap;
	gap: var(--spacing--sm);
	padding: var(--spacing--xl) 0;

	@include mq("md") {
		flex-wrap: nowrap;
	}

	@include mq("md", "max") {
		> :nth-of-type(3) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}
}

.QuickOrder_inputItemNumber {
	flex: 50%;
}

.QuickOrder_inputQuantity {
	width: 80px;
}

.QuickOrder_inputButtonWrapper {
	@include mq("md") {
		display: flex;
		margin-top: auto;

		&___hasError {
			align-items: center;
		}
	}
}

.QuickOrder_inputButton {
	margin: var(--spacing--sm) 0;
	width: 100%;
	height: 40px;

	@include mq("md") {
		width: initial;
		margin: 0;
		bottom: 0;
	}
}

.QuantityInput_inputContainer {
	display: flex;
	flex-direction: row-reverse;
	gap: var(--spacing--sm);
	margin-bottom: var(--spacing--sm);

	input {
		margin: 0;
		max-width: 10rem;
	}

	@include mq("md") {
		flex-direction: row;
	}
}
.QuantityInput_refreshIcon {
	display: grid;
	align-items: center;
	cursor: pointer;
}
