@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.action";

.ProductDetails {
	display: grid;
	grid-template-rows: 2fr 1fr 1fr;
	gap: 2rem;
	margin-bottom: var(--spacing--2xl);
	padding-top: var(--spacing--2xl);

	@include mq("sm") {
		grid-template-rows: unset;
		grid-template-columns: repeat(2, 1fr);
	}
	@include mq("md") {
		grid-template-columns: repeat(3, 1fr);
	}
}
.ProductDetails_category {
	display: inline-block;
	padding: var(--spacing--xs) var(--spacing--sm);
	font-weight: var(--font-weight--bold);
	color: var(--color-light);
	background-color: var(--color-primary-dark-blue);
	margin-bottom: var(--spacing--2xs);
	text-align: center;
}
.ProductDetails_priceLabel {
	display: block;
	margin-bottom: var(--spacing--x2s);
	color: var(--color-primary-dark-blue);
	font-size: var(--font-size--sm);

	&.isHidden {
		display: none;
	}
}
.ProductDetails_priceWrapper {
	margin-bottom: var(--spacing--xl);
	color: var(--color-primary-dark-blue);
}
.ProductDetails_priceValueWrapper {
	display: flex;
	align-items: center;
}

.ProductDetails_discontinued {
	position: relative;
	background-color: var(--color-bg--orange);
	border-left: solid var(--border--xl) var(--color-orange);
	display: flex;
	padding: var(--spacing--xl) var(--spacing--lg);
	margin-bottom: var(--spacing--lg);

	width: 100%;

	@include mq("md") {
		width: 90%;
	}
}

.ProductDetails_discontinuedIconContainer {
	display: grid;
	align-items: center;
}

.ProductDetails_discontinuedTextContainer {
	margin-left: var(--spacing--md);

	:first-child {
		font-weight: 700;
	}
}

.ProductDetails_serial {
	margin-bottom: var(--spacing--sm);
}
.ProductDetails_heading {
	margin-bottom: var(--spacing--sm);
}
.ProductDetails_transportHazard {
	margin-top: var(--spacing--xl);
}
.ProductDetails_serialvalue {
	margin-left: var(--spacing--2xs);
}
.ProductDetails_price {
	font-size: var(--font-size--md);
}

.ProductDetails_currency {
	margin-left: var(--spacing--2xs);
}

.ProductDetails_variantsWrapper {
	margin: var(--spacing--base) 0;
}

.ProductDetails_variants {
	display: flex;
}

.ProductDetails_submitButton {
	@include paragraph--sm;
	width: 100%;
	margin: var(--spacing--base) 0;

	&___loggedIn {
		margin: 0;
	}

	@include mq("sm") {
		@include paragraph--md;
	}
}

.ProductDetails_image {
	border: var(--border--sm) solid var(--color-border--subtle);
	@include aspect-ratio(4, 3);
	padding: 1rem 0; // aspect-ratio's padding-top does not work outside of storybook

	@include mq("md") {
		// Reset aspect-ratio's padding-top
		padding-top: 0; // aspect-ratio's padding-top does not work outside of storybook
	}
}

.ProductDetails_swiperSlide {
	display: grid;
	align-items: center;
	margin: 0 auto;

	cursor: pointer;
}

.ProductDetails_swiperSlideContainer {
	display: grid;
	align-items: center;
}

.swiper-pagination-bullet-active {
	background-color: var(--color-primary-dark-blue);
}

.ProductDetails_action {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin: var(--spacing--sm) 0 var(--spacing--base);
}

.ProductDetails_quantityLabel {
	flex-basis: 100%;
	font-size: var(--font-size--xs);
	font-weight: var(--font-weight--semibold);
	color: var(--color-primary-gray-90);
}

.ProductDetails_input {
	@include input;
	width: 4.8rem;
	padding: var(--spacing--xs) var(--spacing--sm);
	margin-right: var(--spacing--sm);
}

.ProductDetails_cart {
	background-color: var(--color-supporting-sky-blue-20);
	color: var(--color-text--primary);
	padding: var(--spacing--lg);
	margin-bottom: var(--spacing--lg);
	height: fit-content;
}

.ProductDetails_fetchPriceButton {
	width: 100%;
}
