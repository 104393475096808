@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";


.FeaturedProducts{
	margin-bottom: var(--spacing--2xl);
}

.FeaturedProductsItems {
	display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--spacing--lg);
	@include mq("sm") {
		grid-template-columns: repeat(3, 1fr);
	}
	@include mq("lg") {
		grid-template-columns: repeat(4, 1fr);
	}
}

.FeaturedProducts_heading {
	margin-bottom: var(--spacing--md);
}

.FeaturedProducts_filter {
	position: relative;
	width: 100%;
	padding-bottom: var(--grid-gutter);	
	text-align: center;	
	@include mq("sm") {
		display: flex;
    	justify-content: flex-end;
		text-align: end;
	}
}
.FeaturedProducts_loadButton{
	width: 100%;
    justify-content: center;
	font-size: var(--font-size--lg);
	color: var(--color-cta-primary);
	font-weight: var(--font-weight--bold);
	margin-top: var(--spacing--xl);
	&:hover{
		opacity: .8;
	}
}
.FeaturedProducts_plusIcon{
	position: relative;
	display: inline-block;	   
    left: 7px;
}
