/* ------------------------------------ *\
		settings.colors
\* ------------------------------------ */

@import "ui/styles/0-globals/globals.color-functions.scss";

:root {
	// Base colors
	--color-black: #000000;
	--color-white: #ffffff;

	// Primary colors
	--color-primary-dark-blue: #213555;
	--color-primary-gray-90: #393939;
	--color-primary-gray-60: #5f5f5f;
	--color-primary-gray-50: #8d8d8d;
	--color-primary-gray-40: #bdbdbd;
	--color-primary-gray-30: #dedede;
	--color-primary-gray-5: #f6f6f6;
	--color-primary-sand: #e9e9e5;

	// Supporting colors
	--color-supporting-sky-blue-100: #98b9d5;
	--color-supporting-sky-blue-100-rgb: #{toRGB(#98b9d5)};
	--color-supporting-sky-blue-20: #e6eaf1;
	--color-supporting-sky-blue-10: #f0f4f7;
	--color-supporting-light-blue: #c9d4d9;

	// Other colors
	--color-green: #00ab55;
	--color-error: #e81c00;
	--color-yellow: #f2b600;
	--color-orange: #ff9970;
	--color-pale-orange: #ffe5db;

	// UI colors
	--color-cta-primary: var(--color-primary-dark-blue);
	--color-cta-secondary: var(--color-supporting-sky-blue-100);
	--color-ui-positive: var(--color-green);
	--color-ui-negative: var(--color-error);
	--color-ui-warning: var(--color-yellow);

	// Background colors
	--color-bg--brand: var(--color-primary-sand);
	--color-bg--subtle: var(--color-primary-gray-5);
	--color-bg--function: var(--color-supporting-sky-blue-20);
	--color-bg--shoutbox-light: var(--color-supporting-sky-blue-20);
	--color-bg--shoutbox-dark: var(--color-supporting-light-blue);
	--color-bg--orange: rgba(255, 153, 112, 0.25);
	--color-bg--error: rgba(254, 242, 242, 1);

	// Text colors
	--color-text--primary: var(--color-primary-gray-90);
	--color-text--secondary: var(--color-primary-gray-60);
	--color-text--subtle: var(--color-primary-gray-50);

	// Anchor colors
	--color-anchor--primary: var(--color-primary-dark-blue);

	// Border colors
	--color-border--contrast: var(--color-primary-gray-50);
	--color-border--normal: var(--color-primary-gray-40);
	--color-border--subtle: var(--color-primary-sand);

	// Input
	--color-input-text: var(--color-primary-gray-40);
	--color-input-bg: var(--color-white);
	--color-input-bg--focus: var(--color-supporting-sky-blue-20);

	// Colors below are uncertain

	// Shadows
	--shadow-color--milder: rgba(17, 17, 17, 0.1);
	--shadow-color--mild: rgba(17, 17, 17, 0.2);
	--shadow-color--basemild: rgba(17, 17, 17, 0.2);
	--shadow-color--base: rgba(17, 17, 17, 0.5);

	// Overlay
	--overlay-background-color: var(--color-primary-dark-grey);

	// Organisms/Navigation
	--navigarion-item--hover: var(--color-primary-sand);

	// Swiper.js
	--swiper-theme-color: #000 !important;
}
