// @import "ui/styles/2-tools/tools.media-query.scss";

.CheckoutReceipt {
	margin-bottom: var(--spacing--5xl);

	&_info {
		display: inline-block;
		max-width: 560px;
		margin: var(--spacing--2xl) 0;
		padding: var(--spacing--lg);
		background-color: var(--color-supporting-sky-blue-10);
	}

	&_infoItems {
		width: calc(300px - 2 * var(--spacing--lg));

		&_item {
			display: flex;
			align-items: flex-start;
			gap: var(--spacing--lg);

			&:first-child {
				margin-bottom: var(--spacing--sm);
			}

			strong {
				flex: 0 0 50%;
			}
		}
	}

	&_orderStatus {
		color: var(--color-anchor--primary);
	}

	&_orderStatusNotice {
		margin-top: var(--spacing--md);
	}

	&_text {
		max-width: 640px;
		margin-bottom: var(--spacing--5xl);
	}

	&_nextStepsHeading {
		margin-bottom: var(--spacing--lg);
	}

	&_link {
		display: block;
		margin-bottom: var(--spacing--md);
		text-decoration: underline;

		&:hover, &:active, &:focus {
			text-decoration: none;
		}
	}
}
