@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormfieldSelect {
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: "";
	}

	position: relative;
	display: flex;
	flex-flow: column;

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-primary-gray-5);
	}
}
.FormfieldSelect_label {
	@include formfield-label;
}

.FormfieldSelect_wrapper {
	position: relative;
	margin: var(--spacing--2xs) 0;
	@include paragraph--md;

	&:after {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 5rem;
		border-left: var(--border--md) solid var(--color-light);
		background: transparent;
		pointer-events: none;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		right: var(--spacing--sm);
		top: 50%;
		height: 1rem;
		width: 1rem;
		border: solid var(--color-primary-dark-blue);
		border-width: 0 0 var(--border--md) var(--border--md);
		background: transparent;
		pointer-events: none;
		transform: translateY(-66.7%) rotate(-45deg);
	}

	.hasError & {
		color: var(--color-error);

		.FormfieldSelect_element {
			border-color: var(--color-error);
		}
	}

	&.isMultiple {
		&:after,
		&:before {
			display: none;
		}

		.FormfieldSelect_element {
			padding-right: var(--spacing--base);
		}
	}
}

.FormfieldSelect_element {
	@include input;
	padding: var(--spacing--sm) var(--spacing--2xl) var(--spacing--sm)
		var(--spacing--sm);
	cursor: pointer;

	.isDisabled & {
		cursor: not-allowed;
	}

	&::-ms-expand {
		display: none;
	}
}

.FormfieldSelect_label:focus,
.FormfieldSelect_label:hover {
	cursor: auto;
	& ~ .FormfieldSelect_wrapper .FormfieldSelect_element {
		border-color: transparent;
	}
}

.FormfieldSelect_element:focus,
.FormfieldSelect_element:not([disabled]):hover {
	border-color: var(--color-tertiary);
}

.FormfieldSelect___row,
.FormfieldSelect___row-slim {
	@include mq("md") {
		flex-flow: row;
		column-gap: var(--spacing--xl);
	}

	.FormfieldSelect_label {
		flex: 0 0 20%;
		padding-bottom: 0;
	}

	.FormfieldString__input_wrapper {
		@include mq("md") {
			flex: 1 1 100%;
		}
	}
}

.FormfieldSelect___row-slim {
	@include mq("md") {
		column-gap: var(--spacing--md);
	}

	.FormfieldSelect_label {
		flex-basis: auto;
		width: auto;
	}
}
