@import "ui/styles/2-tools/tools.action";

.Button {
	@include button;
	position: relative;
}

.Button_buttonIcon {
	width: 1rem;
	height: 1rem;
	position: absolute;

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	&___back {
		right: 1rem;
		top: 50%;
		transform: translateY(-50%) rotate(-90deg);
	}

	&___front {
		left: 1rem;
		top: 50%;
		transform: translateY(-50%) rotate(90deg);
	}
}
