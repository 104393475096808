@import "ui/styles/2-tools/tools.media-query.scss";

.ShippingDetails {
	padding: var(--spacing--xl) 0;
}

.ShippingDetails_heading {
	margin-bottom: var(--spacing--sm);
}

.ShippingDetails_container {
	margin-top: var(--spacing--sm);
	border-bottom: 1px solid var(--color-primary-sand);
}

.ShippingDetails_label {
	font-weight: var(--font-weight--bold);
	padding: var(--spacing--sm) 0;
	border-bottom: 1px solid var(--color-primary-gray-50);

	// Hide empty cell's border on mobile
	@media screen and (max-width: 798px) {
		&__emptycell {
			border-bottom: none;
		}
	}
}

.ShippingDetails_data_wrapper {
	@include mq("md") {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1.5fr;
	}
}

.ShippingDetails_data {
	padding: var(--spacing--md) var(--spacing--md) var(--spacing--lg) 0;
}
