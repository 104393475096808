@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";


.ProductDiscontinued_boxView {
	display: flex;
	justify-content: space-between;
	margin-top: var(--spacing--sm);
	border-bottom: 1px solid var(--color-primary-gray-50);

	@include mq("md") {
		display: block;
		border: none;
		margin-top: 0;
	}

	&Discontinued {
		position: relative;
		background-color: var(--color-pale-orange);
		margin-bottom: 2rem;

		&:before {
			content: '';
			position: absolute;
			background-color: var(--color-orange);
			width: 0.5rem;
			height: 100%;
		}

		@include mq("md") {
			grid-column: 4 / 6;
		}
	}
}
.ProductDiscontinued_discontinuedContent { 
	padding: 2rem;
	display: flex;
	align-items: center;
}

.ProductDiscontinued_discontinuedIcon { 
	flex-shrink: 0;
}

.ProductDiscontinued_discontinuedText { 
	flex-shrink: 1;
	margin-left: 1rem;
}

.ProductDiscontinued_replacements {
	display: block;
}

.ProductDiscontinued_clickTheLick {
	padding-top: var(--spacing--md);
}