@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.Player {
	@include aspect-ratio(16, 9);

	:global(.react-player__shadow) {
		background: rgba(var(--color-supporting-sky-blue-100-rgb), 0.9) !important; // !important to override react-player library inline styles
		transition: all 0.3s ease;

		@include mq("md") {
			width: 72px !important; // !important to override react-player library inline styles
			height: 72px !important; // !important to override react-player library inline styles
		}

		&:hover, &:focus {
			transform: scale(1.1);
		}
	}

	:global(.react-player__play-icon) {
		border-width: 14px 0 14px 22px !important; // !important to override react-player library inline styles
	}
}
