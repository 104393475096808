@import "ui/styles/2-tools/tools.media-query.scss";

.ModalTab_tab {
	margin-right: calc(2 * var(--spacing--2xl));
	list-style: none;
	cursor: pointer;
	z-index: 2;
	padding: var(--spacing--sm) var(--spacing--xs);
	opacity: 0.5;
	display: none;

	@include mq("md") {
		display: flex;
	}

	@include mq("md", max) {
		margin: 0;
		width: 100%;
		padding: var(--spacing--md) var(--spacing--xs);
		border-top: var(--border--md) solid var(--color-border--contrast);
		&:last-of-type {
			border-bottom: var(--border--md) solid var(--color-border--contrast);
		}
	}

	&.isActive {
		@include mq("sm") {
			opacity: 1;
			border-bottom: var(--border--md) solid var(--color-border--contrast);
		}
	}
}

.ModalTab_header {
	display: flex;
	justify-content: space-between;
}
