@import "ui/styles/2-tools/tools.media-query.scss";

.OrderHistory {
	margin-bottom: var(--spacing--7xl);
	
	&_header {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		padding-bottom: 3rem;
		margin-bottom: 2.5rem;
		border-bottom: 1px solid var(--color-primary-sand);

		@include mq("sm") {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

	&_filter {
		display: flex;
		gap: 2rem;
		align-items: center;
	}

	&_orders {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}
}