@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.ProductsInCart {
	border-bottom: 1px solid var(--color-primary-gray-50);
}

.ProductsInCart_headingContainer {
	@include mq("md") {
		display: flex;
		gap: var(--spacing--md);
	}
}

.ProductsInCart_heading {
	&___mobile {
		@include mq("md") {
			display: none;
		}
	}

	&___desktop {
		display: none;
		@include mq("md") {
			display: block;
		}
	}

	&___basketView {
		padding-bottom: var(--spacing--md);
	}
}

.ProductsInCart_emptyTheCartContainer {
	padding-top: var(--spacing--sm);
	@include mq("md") {
		padding-top: 0;
	}
}

.ProductsInCart_emptyTheCart {
	vertical-align: top;
	border-radius: 0;
	text-decoration: underline;
	font-weight: var(--font-weight--regular);
	color: var(--color-primary-dark-blue);

	padding: 0 initial;
	padding-right: var(--spacing--xs);
	@include mq("md") {
		padding-left: var(--spacing--md);
		border-left: 1px solid var(--color--black);
	}
}

.ProductsInCart_container {
	padding: var(--spacing--2xl) 0;
	border-bottom: 1px solid var(--color-primary-sand);

	@include mq("md") {
		display: grid;
		grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 0.25fr;
		gap: var(--spacing--lg);
		padding: var(--spacing--lg) 0;
	}
}

.ProductsInCart_grandTotalContainer {
	border-bottom: 0;
}

.ProductsInCart_grandTotalLabel {
	grid-column: 6;
}

.ProductsInCart_grandTotal {
	grid-column: 7;
}

.ProductsInCart_shipment {
	display: none;

	@include mq("md") {
		display: grid;
	}
}

.ProductsInCart_dataContainer {
	@include mq("md", "max") {
		> div > :first-of-type {
			padding-top: var(--spacing--sm);
		}
	}
}

.ProductsInCart_overlay {
	display: none;
	border-bottom: 1px solid var(--color-primary-gray-50);

	@include mq("md") {
		display: grid;
	}
}

.ProductsInCart_label {
	font-weight: var(--font-weight--bold);

	@include mq("md") {
		display: flex;
		align-items: flex-end;
	}
}

.ProductsInCart_mobileLabel {
	font-weight: var(--font-weight--regular);
	max-width: 100px;
	margin-bottom: var(--spacing--sm);

	&___total {
		font-weight: var(--font-weight--bold);
	}

	@include mq("md") {
		display: none;
	}
}

.ProductsInCart_mobileProductView {
	display: flex;
	justify-content: space-between;
	margin: var(--spacing--sm) 0;
	border-bottom: 1px solid var(--color-primary-gray-50);
	@include mq("md") {
		display: none;
	}

	&___shipment {
		display: block;
		padding-bottom: var(--spacing--sm);

		@include mq("md") {
			display: none;
		}
	}
}

.ProductsInCart_boxView {
	display: flex;
	justify-content: space-between;
	margin-top: var(--spacing--sm);
	border-bottom: 1px solid var(--color-primary-gray-50);

	@include mq("md") {
		display: block;
		border: none;
		margin-top: 0;
	}

	&___last {
		border-bottom: none;
	}

	&___hidden {
		display: none;

		@include mq("md") {
			display: block;
		}
	}
}

.ProductsInCart_productImage {
	max-width: 120px;
	max-height: 100px;
	border: 1px solid var(--color-primary-sand);
	margin: var(--spacing--md) 0;

	@include mq("md") {
		margin: 0;
	}
}

.ProductsInCart_productId {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: var(--font-size--xs);
	font-style: italic;

	background-color: var(--color-primary-sand);
}

.ProductsInCart_info {
	margin-bottom: var(--spacing--xl);

	@include mq("md") {
		margin-bottom: 0;
	}
}

.ProductsInCart_productNumber {
	color: var(--color-primary-dark-blue);
}

.ProductsInCart_description {
	color: var(--color-primary-dark-blue);
	text-decoration: underline;
}

.ProductsInCart_centerAlignmentBox {
	display: grid;
	align-items: center;
}

.ProductsInCart_transportHazard {
	display: flex;
	margin-top: var(--spacing--xs);
}

.ProductsInCart_transportHazardText {
	margin-right: var(--spacing--sm);
	font-size: var(--font-size--xs);
}

.ProductsInCart_transportHazardIcon {
	cursor: pointer;
}

.ProductsInCart_closeIconRed {
	cursor: pointer;
	display: none;

	@include mq("md") {
		display: block;
	}

	&___mobile {
		display: block;

		@include mq("md") {
			display: none;
		}
	}
}

.ProductsInCart_shipmentDesktopQuantity {
	padding-left: var(--spacing--sm);
}

.ProductsInCart_boldText {
	@include mq("md") {
		font-weight: var(--font-weight--bold);
	}

	&___total {
		font-weight: var(--font-weight--bold);
	}
}

.ProductsInCart_discountText {
	text-decoration: line-through;
	text-decoration-thickness: 1px;
}

.ProductsInCart_shipmentGridBox {
	grid-column-start: 2;
	display: flex;
	justify-content: space-between;
}

.ProductsInCart_shipmentInfo {
	padding-top: var(--spacing--xs);
}

.ProductsInCart_mediumText {
	font-weight: var(--font-weight--semibold);
	color: var(--color-primary-dark-blue);
}

.ProductsInCart_buttonIconContainer {
	position: relative;
	padding: 0 var(--spacing--md);
	cursor: pointer;

	@include mq("md") {
		display: none;
	}
}

.ProductsInCart_buttonIcon {
	position: absolute;
	width: 1rem;
	height: 1rem;
	top: 50%;
	transform: translateY(-50%);

	&.isActive {
		transform: translateY(-50%) rotate(180deg);
	}

	@include transition {
		transition: all var(--trans-time--long);
	}
}

.ProductsInCart_input {
	@include input;
	width: 4.8rem;
	padding: var(--spacing--xs) var(--spacing--sm);
	margin-right: var(--spacing--sm);
}

.ProductsInCart_errors {
	margin: var(--spacing--xs) 0 var(--spacing--lg) 0;
}

.ProductsInCart_error {
	padding: var(--spacing--sm) var(--spacing--md);
	margin: var(--spacing--xs) 0;
	border-radius: var(--corner-size--sm);
	background-color: var(--color-error);
	color: var(--color-white);
}