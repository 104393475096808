@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.SearchField {
	margin: var(--spacing--md) 0;

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-primary-gray-5);
	}
}

.SearchField_wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
}

.SearchField_input {
	width: 100%;
	-webkit-appearance: none;

	@include input;
	padding: var(--spacing--sm) var(--spacing--xs) var(--spacing--sm)
		var(--spacing--2xl);
}

.SearchField_button {
	position: absolute;
	padding: 0;
	min-width: unset;
	background-color: unset;
	border: 0;
	top: 1rem;
	left: var(--spacing--md);
	&:hover,
	&:focus {
		border: 0;
		background-color: var(--color-supporting-sky-blue-10);
	}
}

.SearchField_errorMessage {
	margin-top: var(--spacing--xs);
}