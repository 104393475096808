@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.FooterCard {
	margin-bottom: var(--spacing--lg);
	color: var(--color-primary-dark-blue);
}

.FooterCard_heading {
	text-transform: uppercase;
	margin-bottom: 1em;
	margin-top: 2em;
	text-align: left;
	font-weight: var(--font-weight--bold);

	@include mq("md") {
		margin-top: 0;
	}
}

.FooterCard_links {
	font-size: var(--font-size--lg);
	@include reset-list;
}

.FooterCard_linkItem {
	padding-bottom: var(--spacing--sm);
	font-weight: var(--font-weight--semibold);
}
