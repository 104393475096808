@import "ui/styles/2-tools/tools.list-mixins.scss";

.SocialLinks {
	@include reset-list;
	display: flex;
	margin-bottom: var(--spacing--lg);
}

.SocialLinks_item:not(:last-child) {
	margin-right: var(--spacing--sm);
}

.SocialLinks_link {
	color: var(--color-primary-dark-blue);
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}
