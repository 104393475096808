@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ProductList {
	width: 100%;
	height: 100%;
	position: relative;
}

.ProductListContainer {
	margin-bottom: var(--spacing--xl);
	@include mq("md") {
		display: grid;
		grid-template-columns: minmax(25%, 21rem) 1fr;
		column-gap: var(--spacing--lg);
		grid-template-areas:
			"productViewToggle productViewToggle productSorting"
			"facet productListWrapper productListWrapper";
	}
}

.ProductList_overlay {
	display: none;
	width: 100%;
	height: 100%;
	z-index: 2;
	background: var(--shadow-color--milder);
	position: absolute;
	text-align: center;

	&.isLoading {
		display: block;
	}
}

.ProductList_spinner {
	position: absolute;
	top: var(--spacing--2xl);

	animation: spin 0.7s linear infinite;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	transition: normal;

	@keyframes spin {
		from {
			transform: translateX(-50%) rotate(0deg);
		}
		to {
			transform: translateX(-50%) rotate(360deg);
		}
	}
}

.ProductList_offcanvas {
	// Display: none; below should be controlled in JSX by buttons showing/hiding this offcanvas - maybe changing it to a transition effect with the translate propert
	display: none;
	z-index: var(--z-index--modal);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--color-bg--function);
	padding: var(--spacing--4xl) var(--spacing--base) 0;

	@include mq("md") {
		display: contents;
	}
}

.ProductList_offcanvasButton {
	width: 100%;

	@include mq("md") {
		display: none;
	}
}

.ProductListItems {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: var(--spacing--lg);
	margin-bottom: var(--spacing--2xl);
	@include mq("md") {
		grid-template-columns: repeat(2, 1fr);
	}
	@include mq("lg") {
		grid-template-columns: repeat(3, 1fr);
	}
}

.ProductList_heading {
	width: 100%;
	padding: var(--grid-gutter);
}

.ProductListWrapper {
	margin-bottom: var(--spacing--7xl);
	grid-area: productListWrapper;
}
