@import "ui/styles/2-tools/tools.media-query.scss";

.DoubleButton_container {
	display: flex;
	width: 100%;

	@include mq("md") {
		width: initial;
	}
}

.DoubleButton {
	color: var(--color-primary-gray-60);
	white-space: normal;
	width: 100%;
	padding: var(--spacing--xs) var(--spacing--lg);

	&___left {
		border-radius: var(--corner-size--sm) 0 0 var(--corner-size--sm);
	}

	&___right {
		border-radius: 0 var(--corner-size--sm) var(--corner-size--sm) 0;
	}

	&___isActive {
		background: var(--color-supporting-sky-blue-20);
		color: var(--color-primary-gray-90);
	}

	@include mq("md") {
		width: initial;
	}
}
