@import "ui/styles/2-tools/tools.media-query.scss";

.MobileShipmentContent_mobileShipment {
	@include mq("md") {
		display: none;
	}
}

.MobileShipmentContent_shipmentGridBox {
	grid-column-start: 2;
	display: flex;
	justify-content: space-between;
	padding: 1rem 0;
}

.MobileShipmentContent_mobileShipmentContent {
	display: none;

	&.isActive {
		display: block;

		@include mq("md") {
			display: none;
		}
	}
}

.MobileShipmentContent_mediumText {
	font-weight: var(--font-weight--semibold);
	color: var(--color-primary-dark-blue);
}

.MobileShipmentContent_buttonIconContainer {
	position: relative;
	padding: 0 var(--spacing--md);
	cursor: pointer;

	@include mq("md") {
		display: none;
	}
}

.MobileShipmentContent_buttonIcon {
	color: var(--color-primary-dark-blue);
}

.MobileShipmentContent_shipmentInfo {
	padding-top: var(--spacing--xs);
}

.QuickOrder {
	display: inline-flex;
	flex-wrap: wrap;
	gap: var(--spacing--sm);
	padding: var(--spacing--xl) 0;

	@include mq("md") {
		flex-wrap: nowrap;
	}

	@include mq("md", "max") {
		> :nth-of-type(3) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}
}

.QuickOrder_inputItemNumber {
	flex: 50%;
}

.QuickOrder_inputQuantity {
	width: 80px;
}

.QuickOrder_inputButtonWrapper {
	@include mq("md") {
		display: flex;
		margin-top: auto;

		&___hasError {
			align-items: center;
		}
	}
}

.QuickOrder_inputButton {
	margin: var(--spacing--sm) 0;
	width: 100%;
	height: 40px;

	@include mq("md") {
		width: initial;
		margin: 0;
		bottom: 0;
	}
}
