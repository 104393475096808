@import "ui/styles/2-tools/tools.behavior.scss";

.InformationIcon {
	width: 20px;
	height: 20px;
	text-align: center;

	&___storybook {
		margin: 0 auto;
	}
}

.InformationIcon_icon {
	cursor: pointer;

	&___isActive path {
		fill: var(--color-primary-dark-blue);
		stroke: white;
	}
}

.InformationIcon_bubbleContainer {
	z-index: var(--z-index--header);
	position: absolute;
	transform: translateX(-45%);
	width: 190px;
	margin-top: 10px;

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	opacity: 0;
	visibility: hidden;

	&___isActive {
		opacity: 1;
		visibility: visible;
	}
}

.InformationIcon_bubble {
	text-align: left;
	font-size: var(--font-size--sm);
	background-color: var(--color-white);
	padding: var(--spacing--sm) var(--spacing--md);
	border-radius: 0.5rem;
	border: 1px solid #8d8d8d;
	box-sizing: border-box;
}

.InformationIcon_pointer {
	height: 15px;
	width: 15px;
	background: var(--color-white);
	margin: 0 auto;
	transform: rotate(-135deg);
	border-radius: 0 0 2px 0;
	margin-bottom: -8px;
	border: 1px solid var(--color-primary-gray-50);
	border-top: none;
	border-left: none;
}
