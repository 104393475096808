@import "ui/styles/2-tools/tools.action.scss";
@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.CategoryFilter {
	margin-bottom: var(--spacing--lg);
}

.CategoryFilter_container {
	background-color: var(--color-bg--shoutbox-light);
	border: var(--border--lg) solid var(--color-supporting-sky-blue-100);

	@include mq("sm") {
		background-color: var(--color-white);
		border: none;
	}

	&.CategoryFilter_Modal {
		@include mq("md", "max") {
			@include input;
			padding: 0;
		}
	}
}

.CategoryFilter_wrapper {
	@include reset-list;
	display: none;
	width: 100%;
	border-top: var(--border--md) solid var(--color-border--contrast);
	border-radius: 0 0 var(--corner-size--sm) var(--corner-size--sm);
	padding: var(--spacing--sm) 0;

	&.isOpen {
		display: flex;
		flex-direction: column;
		z-index: 1;
	}

	@include mq("sm") {
		display: grid;
		grid-auto-rows: 1fr;
		border: none;
		gap: var(--spacing--lg);
		margin-bottom: var(--spacing--5xl);
		padding: 0;
		grid-template-columns: repeat(4, 1fr);

		&.isOpen {
			display: grid;
		}
	}
	@include mq("lg") {
		grid-template-columns: repeat(6, 1fr);
	}

	&.CategoryFilter_Modal {
		grid-template-columns: repeat(3, 1fr);
		margin-bottom: var(--spacing--sm);

		@include mq("sm") {
			gap: var(--spacing--md);
		}
	}
}

.CategoryFilter_button {
	width: 100%;
	border: none;
	border-radius: 0;
	text-align: left;
	padding: var(--spacing--xs) var(--spacing--lg) var(--spacing--xs)
		var(--spacing--md);
	background-color: var(--color-bg--shoutbox-light);

	@include mq("sm") {
		border: 1px solid var(--color-cta-primary);
		border-radius: 0;
		display: inline-flex;
		text-align: left;
		min-height: 9rem;
		height: 100%;
		padding: var(--spacing--md) var(--spacing--7xl) var(--spacing--md)
			var(--spacing--md);
		white-space: normal;
		background-color: var(--color-white);

		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--bold);
		line-height: var(--line-height--sm);
	}
}

.CategoryFilter_toggle {
	position: relative;
	width: 100%;
	border-radius: var(--corner-size--sm);
	padding: var(--spacing--sm) var(--spacing--lg) var(--spacing--sm)
		var(--spacing--md);
	text-align: left;
	font-weight: var(--font-weight--bold);

	&.isOpen {
		border-radius: var(--corner-size--sm) var(--corner-size--sm) 0 0;
		border-bottom-color: transparent;
	}

	@include mq("sm") {
		display: none;
		/* width: 14rem; */
	}

	@include mq("md") {
		width: 14rem;
	}

	&.CategoryFilter_Modal {
		font-weight: var(--font-weight--normal);
		font-size: var(--font-size--md);
	}
}

.CategoryFilter_buttonIcon {
	width: 1rem;
	height: 1rem;
	position: absolute;
	right: 1rem;
	top: 1.5rem;
	@include transition {
		transition: all var(--trans-time--long);
	}
	.isOpen & {
		transform: rotate(180deg);
	}
}

.CategoryFilter_heading {
	text-transform: uppercase;
	margin-bottom: var(--spacing--md);
}

.CategoryFilter_smallHeading {
	@include formfield-label;
	padding: var(--spacing--md) 0 var(--spacing--sm) 0;
}

.CategoryFilter_number {
	font-size: var(--font-size--sm);
	font-weight: normal;
	color: var(--color-text--secondary);
	margin-left: var(--spacing--md);
}
