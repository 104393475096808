@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.list-mixins.scss";

.Navigation_mobile {
	@include mq("md") {
		display: none;
	}

	.Navigation_list {
		@include reset-list;

		@include mq("md", "max") {
			width: 100%;
		}

		@include mq("md") {
			display: flex;
			position: relative;
		}
	}

	.Navigation_link {
		color: var(--color-cta-primary);
		font-size: var(--font-size--3xl);
		line-height: var(--line-height--xs);
		font-weight: var(--font-weight--semibold);
		text-transform: uppercase;
		position: relative;
		padding: var(--spacing--sm) 0;

		@include mq("md") {
			@include paragraph--sm;
			padding: 0;
			font-weight: var(--font-weight--semibold);
			color: var(--text-color--primary);
		}
	}

	.Navigation_buttonIconContainer {
		position: relative;
		padding: 0 var(--spacing--md);
		cursor: pointer;

		.Navigation_buttonIcon {
			position: absolute;
			width: 1rem;
			height: 1rem;
			top: 50%;
			transform: translateY(-50%);

			&.isActive {
				transform: translateY(-50%) rotate(180deg);
			}

			@include transition {
				transition: all var(--trans-time--long);
			}
		}
	}

	.Navigation_dropDown {
		display: flex;
	}

	.Navigation_item {
		margin-bottom: var(--spacing--sm);
		display: flex;
		position: relative;

		// MOBILE / TABLET
		@include mq("md", "max") {
			flex-wrap: wrap;

			// Level 1
			.Navigation_list {
				.Navigation_item {
					border-top: 1px solid var(--color-border--normal);

					&:last-child {
						border-bottom: 1px solid var(--color-border--normal);
					}

					.Navigation_dropDown {
						width: 100%;
						justify-content: space-between;
						.Navigation_link {
							@include heading--lg;

							span {
								@include paragraph--sm;
							}
						}
					}
				}

				// Level 2
				.Navigation_list {
					padding: var(--spacing--xs) 0;

					.Navigation_item {
						border: none;
						margin: 0;
						padding: var(--spacing--xs) 0;

						.Navigation_link {
							padding: 0;

							@include paragraph--sm;
							text-transform: inherit;
						}
					}
				}
			}
		}
	}
}

.Navigation_desktop {
	@include mq("md", "max") {
		display: none;
	}

	.Navigation_list {
		@include reset-list;

		@include mq("md") {
			display: flex;
			flex-direction: row;
		}
	}

	.Navigation_dropDownContainer {
		display: none;
		position: fixed;
		z-index: 2;
		left: 0;
		width: 100%;
		background: var(--color-primary-gray-5);
		padding-top: var(--spacing--lg);
	}

	.Navigation_dropDownContainerInner {
		display: grid;
		gap: var(--spacing--md);
		grid-template-columns: repeat(3, 1fr);
		width: 70%;
		margin: 0;
		padding: 0;
	}

	.Navigation_dropDownItemContainer {
		position: relative;
		padding: var(--spacing--lg) 0;
		margin: 0;
		list-style: none;

		svg {
			display: none;
		}
	}

	.Navigation_dropDownItem {
		text-transform: uppercase;
		display: flex;
	}

	.Navigation_dropDownItemHeader {
		font-weight: var(--font-weight--semibold);
	}

	.Navigation_dropDownItemParagraph {
		padding-left: var(--spacing--sm);
		align-self: flex-end;
		color: var(--color-primary-gray-60);
	}

	.Navigation_dropDownItemChildrenContainer {
		padding: var(--spacing--sm) 0;
	}

	.Navigation_dropDownItemChildren {
		display: block;
		padding: var(--spacing--xs) 0;
	}

	.Navigation_item {
		position: relative;
		margin-bottom: var(--spacing--sm);
		padding-right: var(--spacing--2xl);

		@include mq("md") {
			margin-bottom: 0;
		}

		&.isActive {
			.Navigation_buttonIcon {
				transform: rotate(180deg);
				cursor: pointer;
			}

			.Navigation_dropDownContainer {
				display: block;
			}
		}
	}

	.Navigation_link {
		color: var(--color-cta-primary);
		font-size: var(--font-size--3xl);
		line-height: var(--line-height--xs);
		font-weight: var(--font-weight--semibold);
		text-transform: uppercase;

		@include mq("md") {
			@include paragraph--sm;
			font-weight: var(--font-weight--semibold);
			color: var(--text-color--primary);
		}
	}

	.Navigation_buttonIcon {
		width: 1rem;
		height: 1rem;
		position: absolute;
		right: 2.3rem;
		top: 0.6rem;
		display: none;

		@include transition {
			transition: all var(--trans-time--long);
		}

		@include mq("md") {
			display: block;
		}
	}
}
