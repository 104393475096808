@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.ShoppingCart {
	display: flex;
}

.ShoppingCart_button {
	position: relative;
	background: none;
	border: none;
	margin-left: auto;	
	display: flex;	
	align-items: center;
    transform: perspective(1px) translateZ(0);   
 
	&:hover .ShoppingCart_icon {		
    	transform: translateX(2px);
	}
}
.ShoppingCart_icon {
	margin-right: var(--spacing--sm);
	transform: translateZ(0);  
	@include transition {
		transition: transform var(--trans-time--long) var(--ease--dynamic);
	}   	
}
.ShoppingCart_text {
	display: none;
	
	@include mq("md") {
		display: block;
		color: var(--color-text--primary);
		@include paragraph--sm;
	}
}

.ShoppingCart_button___hasProduct:after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	width: var(--icon-size--xs);
	height: var(--icon-size--xs);
	border-radius: var(--corner-circle);
	background-color: var(--color-white);
}

.ShoppingCart_window {
	display: flex;
	transform: translateY(-100%);
	position: absolute;
	width: 100vw;	
	max-width: 100%;
	max-height: 100vh;
	top: 0;
	right: 0;
	flex-direction: column;
	justify-content: space-between;
	padding: var(--spacing--2xs) 0;
	background-color: var(--color-white);
	color: var(--color-black);
	z-index: 5;
	@include transition {
		transition: transform var(--trans-time--long);
	}  	
}

.ShoppingCart_window___visible {
	transform: translateY(0%);
}

.ShoppingCart_overlay {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: var(--overlay-background-color);
	opacity: .5;
	cursor: pointer;
}
.ShoppingCart_overlay___visible {
	display: block;
}

.ShoppingCart_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--spacing--xs) 0;
	border-bottom: var(--border--sm) solid var(--color-border--subtle);	
}

.ShoppingCart_heading {
	font-size: var(--font-size--lg);
	margin-left: var(--spacing--sm);
}

.ShoppingCart_closeBtn {
	background: none;
	border: none;
	border-radius: var(--corner-circle);
	padding: var(--spacing--xs);
	margin-right: var(--spacing--xs);
	&:hover,
	&:focus {
		background-color: var(--color-primary-gray-5);
	}
}

.ShoppingCart_products {
	padding: 0 var(--spacing--md);
	overflow-y: auto;
}

.ShoppingCart_checkout {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: var(--spacing--md);
}

.ShoppingCart_checkoutButton {
	width: 100%;
}

.ShoppingCart_checkoutTotal {
	width: 100%;
	margin-bottom: var(--spacing--md);
}
.ShoppingCart_checkoutTotalRow {
	display: flex;
	justify-content: space-between;
	font-size: var(--font-size--lg);
	margin-bottom: var(--spacing--xs);
	&:last-child {
		margin-bottom: 0;
	}
}
