@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Paragraph {
	&___xs {
		@include paragraph--xs;
	}
	&___sm {
		@include paragraph--sm;
	}

	&___md {
		@include paragraph--md;
	}

	&___lg {
		@include paragraph--lg;
	}
}
