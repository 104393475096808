@import "ui/styles/2-tools/tools.media-query.scss";

.HelpForm_Description {
	color: var(--color-primary-dark-blue);
}

.HelpForm_Categories {
	margin-left: calc(-1 * var(--spacing--base));
	margin-right: calc(-1 * var(--spacing--base));
}

.HelpForm_submitButtonContainer {
	order: 11;
	flex: 0 0 100%;
	margin-top: var(--spacing--md);

	@include mq("md") {
		margin-top: var(--spacing--sm);
		margin-left: auto;
	}

	button {
		width: 100%;
	}
}
