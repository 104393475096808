@import "ui/styles/2-tools/tools.media-query.scss";

.PageSpinner_container {
	width: 100%;
	text-align: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.PageSpinner {
	width: var(--icon-size--lg);
	height: var(--icon-size--lg);
	border-top-color: var(--color-primary-dark-blue);
	border-left-color: var(--color-primary-dark-blue);
	animation: spinner 400ms linear infinite;
	border-bottom-color: transparent;
	border-right-color: transparent;
	border-style: solid;
	border-width: var(--border--md);
	border-radius: 50%;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: middle;
}

.PageSpinner_text {
	padding: var(--spacing--sm) var(--spacing--lg);
	position: relative;
	margin: 0 auto;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.9);
	width: 500px;
	max-width: 100%;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}

.PageSpinner__bg {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	z-index: 1000;
	user-select: none;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.PageSpinner___large {
	width: var(--icon-size--2xl);
	height: var(--icon-size--2xl);
	border-width: var(--border--2xl);
}

.PageSpinner___small {
	width: var(--icon-size--md);
	height: var(--icon-size--md);
	border-width: var(--border--md);
}

.PageSpinner___error {
	border-top-color: var(--color-error);
	border-left-color: var(--color-error);
}

.PageSpinner___slow {
	animation: spinner 1s linear infinite;
}

.PageSpinner_screenReader {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
	.PageSpinner {
		display: none;
	}

	.PageSpinner_loadingMsg {
		position: relative;
		width: auto;
		height: auto;
		padding: var(--spacing--xs);
		overflow: hidden;
		clip: auto;
		white-space: nowrap;
		border: var(--border--lg) solid var(--color-error);
		color: var(--color-light);
		font-size: var(--font-size--lg);
		background: var(--color-error);
	}
}
