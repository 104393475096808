/* ------------------------------------ *\
		tools.action
\* ------------------------------------ */

@import "ui/styles/2-tools/tools.behavior.scss";

// Action
@mixin action {
	padding: 0;
	background: transparent;
	border-color: transparent;
	text-decoration: none;
	cursor: pointer;

	&:hover, &:focus, &:active {
		text-decoration: underline;
	}
}

// Button
@mixin button {
	@include action;
	display: inline-block;
	padding: var(--spacing--xs) var(--spacing--lg);
	text-decoration: none;
	line-height: var(--line-height--lg);
	border-radius: var(--corner-size--sm);
	border: var(--border--md) solid transparent;
	text-align: center;
	min-width: 110px;
	font-weight: var(--font-weight--medium);
	white-space: nowrap;

	&:hover, &:focus, &:active {
		text-decoration: none;
	}

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	&___primary {
		color: var(--color-white);
		background: var(--color-cta-primary);
		font-size: var(--font-size--sm);
		border: 1px solid var(--color-cta-primary);
		padding: var(--spacing--sm) var(--spacing--lg);
		&:hover,
		&:focus {
			color: var(--color-cta-primary);
			border: 1px solid var(--color-cta-primary);
			background: var(--color-white);
		}
	}

	&___primarySmall {
		color: var(--color-white);
		background: var(--color-cta-primary);
		font-size: var(--font-size--sm);
		border: 1px solid var(--color-cta-primary);
		padding: var(--spacing--xs) var(--spacing--md);
		&:hover,
		&:focus {
			color: var(--color-cta-primary);
			border: 1px solid var(--color-cta-primary);
			background: var(--color-white);
		}
	}

	&___secondary {
		color: var(--color-primary-gray-90);
		background: var(--color-white);
		font-size: var(--font-size--sm);
		border: 1px solid var(--color-primary-gray-40);
		padding: var(--spacing--sm) var(--spacing--lg);
		&:hover,
		&:focus {
			background: var(--color-cta-primary);
			color: var(--color-white);
		}

		&.isCategoryFilterActive {
			background: var(--color-cta-primary);
			color: var(--color-white);
		}
	}

	&___secondarySmall {
		color: var(--color-primary-gray-90);
		background: var(--color-white);
		font-size: var(--font-size--sm);
		border: 1px solid var(--color-primary-gray-40);
		padding: var(--spacing--xs) var(--spacing--md);
		&:hover,
		&:focus {
			background: var(--color-cta-primary);
			color: var(--color-white);
		}
	}

	&___inactive,
	&:disabled {
		opacity: 0.3;
		pointer-events: none;

		&:hover,
		&:focus {
			transition: none;
		}
	}

	&___transparent {
		color: var(--color-primary-dark-blue);
		background: transparent;
		font-size: var(--font-size--md);
		border: 1px solid var(--color-primary-gray-40);
		padding: var(--spacing--sm) var(--spacing--lg);
		&:hover,
		&:focus {
			background: var(--color-cta-primary);
			color: var(--color-white);
		}
	}
}

// Reset Button
@mixin reset-button {
	background: none;
	color: unset;
	min-width: unset;
	border: 0;
}

// Input
@mixin input {
	@include paragraph--md;
	background-color: var(--color-white);
	padding: var(--spacing--sm) var(--spacing--base);
	border: none;
	border-radius: 4px;
	width: 100%;
	color: var(--color-primary-gray-90);
	outline: 1px solid var(--color-primary-gray-40);

	&:hover {
		background-color: var(--color-supporting-sky-blue-10);
	}

	&:focus {
		color: var(--color-primary-gray-90);
		background-color: var(--color-supporting-sky-blue-10);
		outline: 2px solid var(--color-supporting-sky-blue-100);
	}

	&.hasError {
		color: var(--color-error);
		background-color: var(--color-bg--error);
		outline: 2px solid var(--color-error);
	}

	&--light-outline {
		outline: 1px solid var(--color-primary-sand);
	}

	&::placeholder {
		color: var(--color-primary-gray-40);
	}
}
