@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.ProductSorting{
	color: var(--color-text--primary);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--xs);
	grid-area: productSorting;
	padding-bottom: var(--spacing--lg);
	border-bottom: var(--border--sm) solid var(--color-border--contrast);

	@include mq("md") {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 0;
		margin-bottom: var(--spacing--xl);
		border-bottom: 0;
	}
}

.ProductSorting_dropdown {
	position: relative;
}

.ProductSorting_toggle {
	position: relative;
	width: 100%;
	border: var(--border--sm) solid var(--color-border--normal);
	border-radius: var(--corner-size--sm);
	padding: var(--spacing--xs) var(--spacing--lg) var(--spacing--xs) var(--spacing--sm);
	text-align: left;

	&.isOpen{
		border-radius: var(--corner-size--sm) var(--corner-size--sm) 0 0;
		border-bottom-color: transparent;
	}
	&:hover,
	&:focus {
		background: var(--color-white);
		color: var(--color-cta-primary);
	}
	@include mq("md") {
		width: auto;
		min-width: 16rem;
	}

}

.ProductSorting_controls {
	display: flex;
	width: 100%;
	flex-direction: column;
	z-index: 1;
	background-color: var(--color-white);
	border-bottom: var(--border--sm) solid var(--color-border--normal);
	border-left: var(--border--sm) solid var(--color-border--normal);
	border-right: var(--border--sm) solid var(--color-border--normal);
	border-radius: 0 0 var(--corner-size--sm) var(--corner-size--sm);

	@include mq("md") {
		position: absolute;
		width: 14rem;
		left: 0;
		right: 0;
		top: 2.8rem;
	}
}

.ProductSorting_sortingLabel{
	display: block;
	font-size: var(--font-size--sm);
	line-height: var(--line-height--lg);
	font-weight: var(--font-weight--semibold);
	color: var(--color-text--subtle);
	white-space: nowrap;
	min-width: var(--spacing--2xl);
	margin-bottom: var(--spacing--sm);

	@include mq('md') {
		margin-bottom: 0;
		margin-right: var(--spacing--md);
		font-weight: var(--font-weight--normal);
	}
}

.ProductSorting_parameterButton {
	padding: var(--spacing--xs) var(--spacing--xl) var(--spacing--xs) var(--spacing--sm);
	border-bottom: 0;
	border-radius: 0;
	text-align: left;

	&:first-child {
		border-top: 0;
	}

}
.ProductSorting_buttonIcon{
	width: 1rem;
    height: 1rem;
    position: absolute;
	right: 1rem;
    top: 0.7rem;
	@include transition {
		transition: all var(--trans-time--long);
	}
	.isOpen &{
		transform: rotate(180deg);
	}
}
