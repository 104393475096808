@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.ProductCard {
	padding: var(--spacing--md);
	border: var(--border--sm) solid var(--color-border--subtle);
	display: flex;
    flex-direction: column;
}

.ProductCard_link {
	display: block;
	text-decoration: none;
}

.ProductCard_image {
	position: relative;
	margin-bottom: var(--spacing--sm);
}
.ProductCard_imageBox {
	// @include aspect-ratio(4, 3);
}

.ProductCard_imageElement {
	min-height: 250px;
	transition: all 0.2s ease-in-out;
	.ProductCard_link:hover &,
	.ProductCard_link:focus & {
		transform: scale(1.02);
	}

	@include mq("md") {
		min-height: 300.24px;
	}

	@include mq("lg") {
		min-height: 205.33px;
	}
}
.ProductCard_priceAndMeta {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: var(--spacing--sm);

}
.ProductCard_meta {
	color: var(--color-text--secondary);
	font-size: var(--font-size--xs);
	line-height: var(--font-size--xl);
}

.ProductCard_price {
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--semibold);
	color: var(--color-primary-dark-blue);
}

.ProductCard_priceCurrency {
	margin-left: var(--spacing--2xs);
}

.ProductCard_details {
	color: var(--color-text--primary);
}

.ProductCard_category {
	font-size: var(--font-size--sm);
	position: absolute;
	left: -999999px;
	opacity: 0;
	visibility: hidden;
}

.ProductCard_number {
	font-size: var(--font-size--sm);
}

.ProductCard_name {
	font-size: var(--font-size--sm);
	line-height: var(--line-height--2xl);
	font-weight: var(--font-weight--bold);
	margin-bottom: var(--spacing--lg);

	@include mq("sm") {
		margin-bottom: var(--spacing--xs);
		height: calc(var(--font-size--sm) * var(--line-height--2xl) * 2);
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

.ProductCard_variantsWrapper {
	display: none;
	padding-top: var(--spacing--xs);
}

.ProductCard_variantList {
	margin-bottom: var(--spacing--xs);
	list-style: none;
	display: flex;
}

.ProductCard_variantListItem {
	margin-right: var(--spacing--sm);
	color: var(--color-dark);
}

.ProductCard_soldOut {
	margin-top: var(--spacing--sm);
	color: var(--color-error);
}

.ProductCard_variant {
	@include visually-hidden;
}
.ProductCard_input {
	width: 4.8rem;
	border: 1px solid var(--color-cta-primary);
	padding: var(--spacing--xs) var(--spacing--sm);
	border-radius: var(--corner-size--sm);
	line-height: var(--line-height--sm);
	text-align: left;
	margin-right: var(--spacing--sm);
}

.ProductCard_cta {
	width: 100%;
	min-width: auto;
}

.ProductCard_secondaryCta {
	width: 100%;
	min-width: auto;
	margin-top: var(--spacing--sm);
}

.ProductCard_actions {
	margin-top: auto;
	margin-bottom: 0;
}

.ProductCard_action {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}
