@import "ui/styles/2-tools/_tools.behavior.scss";
@import "ui/styles/2-tools/_tools.typography-mixins.scss";

.AccordionItem_triggerWrapper,
.AccordionItem_panel:not([hidden]) {
	border-top: var(--border--sm) solid var(--color-border--contrast);
}

.AccordionItemContainer:last-of-type {
	.AccordionItem_triggerWrapper {
		border-bottom: var(--border--sm) solid var(--color-border--contrast);
	}

	.AccordionItem_panel {
		border-top: none;
	}
}

.AccordionItem_trigger {
	background: none;
	color: var(--color-primary-gray-90);
	display: block;
	margin: 0;
	position: relative;
	text-align: left;
	width: 100%;
	outline: none;
	border-style: none;
	padding: 0;
	@include heading--sm;
}

.AccordionItem_trigger:focus .AccordionItem_icon,
.AccordionItem_trigger:hover .AccordionItem_icon {
	border-color: var(--color-primary-dark-blue);
}

.AccordionItem_trigger[aria-expanded="true"] .AccordionItem_icon::after {
	transform: rotate(0);
}

.AccordionItem button::-moz-focus-inner {
	border: 0;
}

.AccordionItem_title {
	display: block;
	pointer-events: none;
	padding: var(--spacing--lg) 0 var(--spacing--lg) var(--spacing--xl);
	line-height: var(--line-height--md);
	color: var(--color-primary-gray-90);
}

.AccordionItem_icon {
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 50%;

	&::before,
	&::after {
		content: "";
		width: 1.2rem;
		height: var(--border--md);
		pointer-events: none;
		position: absolute;
		top: 50%;
		background-color: var(--color-primary-gray-90);
		@include transition {
			transition: transform var(--trans-time--shorter)
				var(--ease--dynamic);
		}
	}

	&::after {
		transform: rotate(90deg);
	}
}

.AccordionItem_panel {
	margin: 0;
	padding: var(--spacing--lg) 0 var(--spacing--lg);
}

/* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
.AccordionItem_panel[hidden] {
	display: none;
}

.AccordionItem_text {
	color: var(--color-primary-gray-50);
	list-style-position: inside;
	padding-left: var(--spacing--lg);
}
