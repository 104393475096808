.SkipToMain {
	max-width: 17rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	display: block;
	overflow: hidden;
	font-size: var(--font-size--lg);
	height: var(--spacing--xl);
	line-height: var(--spacing--xl);
	max-height: 1px;
	margin-top: -1px;
	color: var(--color-dark);
	&:focus,
	&:hover {
		max-height: 4rem;
		text-decoration: underline;
		border: 0.2rem solid var(--color-dark);
		background: var(--color-light);
	}
	&:not(:focus) {
		max-height: 1px;
		margin-top: -1px;
	}
}
