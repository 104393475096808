.ArticleHeader {
	margin-top: var(--spacing--xl);
	margin-bottom: var(--spacing--xl);
}

.ArticleHeader_heading {
	width: 100%;
	margin-bottom: var(--spacing--lg);
	color: var(--color-primary-dark-blue);
}

.ArticleHeader_subheading {
	width: 100%;
	font-size: var(--font-size--2xl);
	font-weight: var(--font-weight--bold);
	margin-bottom: var(--spacing--md);
}


.ArticleHeader_lead {
	font-size: var(--font-size--xl);
}

.ArticleHeader_date {
	margin-top: var(--spacing--xl);
}