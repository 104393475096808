@import "ui/styles/2-tools/tools.media-query.scss";


.LoginStatus_container {
	background-color: var(--color-primary-sand);
	padding: var( --spacing--xl);
	width: 100vw;
	height: 100vh;
	color: var(--color-primary-dark-blue);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing--5xl);
	position: absolute;
	left: 0;
	z-index: 2;
	overflow: auto;

	@include mq("md") {
		width: auto;
		height: auto;
		left: auto;
		right: 0;
		bottom: 0;
		transform: translateY(100%);
		padding: var( --spacing--3xl);
		row-gap: var(--spacing--2xl);
	}
}

.LoginStatus_userContainer {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	column-gap: var(--spacing--3xl);
	row-gap: var(--spacing--2xl);
	order: 0;

	@include mq("md") {
		flex-direction: row;
	}
}

.LoginStatus_user {
	margin-bottom: var(--spacing--sm);
}

.LoginStatus_buttonContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: var( --spacing--md);

	@include mq("md") {
		justify-content: right;
	}
}

.LoginStatus_addressesContainer {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing--lg);
	order: 1;

	@include mq("md") {
		order: 0;
	}
}

.LoginStatus_addresses {
	row-gap: var(--spacing--xl);
}

.LoginStatus_address {
	@include mq("md") {
		max-width: 16rem;
	}
}

.LoginStatus_linksContainer {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing--md);
}

.LoginStatus_links {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing--sm);
}