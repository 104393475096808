@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormfieldString {
	position: relative;
	display: flex;
	flex-flow: column;
	color: var(--color-primary-gray-90);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-primary-gray-5);
	}
}

.FormfieldString_input {
	@include input;
}

.FormfieldString_label {
	@include formfield-label;
}

.FormfieldString_input:not([disabled]):hover,
.FormfieldString_label:hover ~ .FormfieldString_input:not([disabled]),
.FormfieldString_input:focus {
	//border-color: var(--color-tertiary);
}

.FormfieldString--row {
	@include mq("md") {
		flex-flow: row;
		column-gap: var(--spacing--lg);
	}

	.FormfieldString_label {
		padding-bottom: 0;

		@include mq("md") {
			flex: 0 0 22%;
			width: auto;
		}
	}

	.FormfieldString__input_wrapper {
		@include mq("md") {
			flex: 1 1 100%;
		}
	}
}
