/* ------------------------------------ *\
		settings.typography
\* ------------------------------------ */

:root {
	// Font family
	--font-primary: "IBM Plex Sans", sans-serif;
	--font-secondary: "Open Sans", sans-serif;

	// Font weights
	--font-weight--light: 200;
	--font-weight--normal: 400;
	--font-weight--medium: 500;
	--font-weight--semibold: 600;
	--font-weight--bold: 700;
	--font-weight--black: 800;
	--font-weight--extrablack: 900;

	// Font sizes
	--font-size--2xs: 1rem;
	--font-size--xs: 1.2rem;
	--font-size--sm: 1.4rem;
	--font-size--md: 1.6rem;
	--font-size--lg: 1.8rem;
	--font-size--xl: 1.9rem;
	--font-size--2xl: 2.4rem;
	--font-size--3xl: 3rem;
	--font-size--4xl: 3.3rem;
	--font-size--5xl: 4rem;

	// Line heights
	--line-height--2xs: 1.175;
	--line-height--xs: 1.211;
	--line-height--sm: 1.222;
	--line-height--md: 1.333;
	--line-height--lg: 1.424;
	--line-height--xl: 1.438;
	--line-height--2xl: 1.5;
	--line-height--3xl: 1.556;
	--line-height--4xl: 1.714;
}
