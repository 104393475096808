@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.VideoCard {
	position: relative;
	height: 100%;
	margin-bottom: calc(var(--grid-gutter) * 2);

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		&___row {
			display: flex;
			flex-direction: row;
		}

		&___rowReverse {
			display: flex;
			flex-direction: row-reverse;
		}
	}

	&___bordered {
		border: var(--border--sm) solid var(--color-primary-gray-5);
	}

	&___padded {
		padding: var(--spacing--md);
	}

	&___padded-x2 {
		padding: var(--spacing--xl);
	}
}

.VideoCard_textbox {
	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		.VideoCard___row &,
		.VideoCard___rowReverse & {
			width: 66.6666666666%;
		}
		.VideoCard___row & {
			padding-left: calc(var(--grid-gutter) * 2);
		}

		.VideoCard___rowReverse & {
			padding-right: calc(var(--grid-gutter) * 2);
		}
	}
}

.VideoCard_kicker {
	font-size: var(--font-size--sm);
	text-transform: uppercase;
	margin-bottom: 0.5em;
	color: var(--color-primary-dark-blue);
	font-weight: var(--font-weight--bold);
}

.VideoCard_heading {
	margin-bottom: 0.5em;
}

.VideoCard_text p {
	margin-bottom: 1em;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.VideoCard_action {
	margin-top: 1em;
}

.VideoCard_link {
	display: block;
	color: inherit;
	text-decoration: none;
}

.VideoCard_media {
	display: block;
	margin-bottom: 0.5em;

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		.VideoCard___row &,
		.VideoCard___rowReverse & {
			width: 33.3333333333%;
		}
	}
}

.VideoCard_mediabox {
	@include aspect-ratio(16, 9);
}

.VideoCard_date {
	font-size: var(--font-size--sm);
	margin-bottom: 0.5em;
}
