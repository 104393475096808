@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.action.scss";

.StepNavigation {
	display: flex;
	flex-direction: column;
}

.StepNavigation_indicator {
	display: flex;
}

.StepNavigation_content {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	margin: var(--spacing--4xl) 0;

	@include mq("md") {
		flex-direction: row;
	}

	@include mq("lg") {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
}

.StepNavigation_heading {
	@include mq("lg") {
		grid-column: 2;
		margin: 0 var(--spacing--md);
		text-align: center;
	}
}

.StepNavigation_buttons {
	display: inline-flex;
	align-items: center;
	margin-bottom: var(--spacing--xl);
	gap: var(--spacing--md);

	@include mq("md") {
		margin-left: auto;
		margin-bottom: 0;
		margin-right: var(--spacing--lg);
	}

	@include mq("lg") {
		grid-column: 3;
	}
}

.StepNavigation_buttonPrevious,
.StepNavigation_buttonNext {
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	user-select: none;

	&:not(:only-child) {
		flex-basis: 50%;
	}
}

.StepNavigation_buttonPrevious {
	border: 1px solid var(--color-supporting-sky-blue-20);
	background-color: var(--color-supporting-sky-blue-20);
	color: var(--color-primary-dark-blue);

	&___iconLeft {
		margin-right: calc(var(--spacing--sm) * 1.5);
	}
}

.StepNavigation_buttonNext {
	&___iconRight {
		margin-left: calc(var(--spacing--sm) * 1.5);
	}

	&:hover &___iconRight path {
		stroke: var(--color-primary-dark-blue);
	}
}
