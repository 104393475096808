.Container {
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--spacing--base);
	padding-right: var(--spacing--base);

	&___standard {
		max-width: 124.8rem;
	}

	&___small {
		max-width: 96rem;
	}

	&___slim {
		max-width: 72rem;
	}

	&___skinny {
		max-width: 65rem;
	}

	&___textLeft {
		text-align: left;
	}

	&___textCenter {
		text-align: center;
	}

	&___textRight {
		text-align: right;
	}
}