@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";

.NavigationStep {
	margin: auto var(--spacing--xs);

	&_container {
		display: grid;
		grid-template-columns: auto auto 1fr;
		gap: var(--spacing--xs);
		align-items: center;
	}

	&___expand {
		width: 100%;
	}

	&_lastStep {
		width: unset;
	}

	@include mq("sm") {
		width: 100%;

		&_container {
			gap: var(--spacing--sm);
		}

		&___expand {
			width: 100%;
		}
	}
}

.NavigationStep_stepNumber {
	&___wrapper {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-color: var(--color-primary-sand);
		padding: 12px 12px;

		display: flex;
		align-items: center;
		justify-content: center;

		&___isActive {
			background-color: var(--color-primary-dark-blue);
			transition: background-color var(--trans-time--standard) linear;
		}

		&___isSuccess {
			background-color: #e3f2e9;
		}
	}

	&___text {
		color: var(--color-primary-gray-50);
		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--bold);
		user-select: none;

		&___isActive {
			color: var(--color-white);
		}

		&___isSuccess {
			color: var(--color-green);
		}
	}
}

.NavigationStep_stepTitle {
	display: none;

	&___text {
		font-size: var(--font-size--md);
		color: var(--color-primary-gray-50);
		user-select: none;

		&___isActive {
			color: var(--color-primary-dark-blue);
		}

		&___isSuccess {
			color: var(--color-primary-gray-50);
		}
	}

	&___isActive {
		@include display-block;
		opacity: 1;
		transition: opacity var(--trans-time--long) linear;
	}

	@include mq("sm") {
		@include display-block;
	}
}

.NavigationStep_line {
	background-color: var(--color-primary-sand);

	width: 100%;
	height: 2px;
	margin: 0 var(--spacing--xs);
}
