@import "ui/styles/2-tools/tools.media-query.scss";

.CostDetails {
	padding: var(--spacing--xl) 0;
}

.CostDetails_view {
	margin-top: var(--spacing--sm);
	border-bottom: 1px solid var(--color-primary-gray-50);

	@include mq("md") {
		display: flex;
		flex-direction: column;
	}
}

.CostDetails_headingWrapper {
	padding: var(--spacing--sm) 0 var(--spacing--xs);
	border-bottom: 1px solid var(--color-primary-gray-50);

	@include mq("md") {
		display: grid;
		grid-template-columns: auto 340px;
		justify-content: flex-end;
	}

	&___emptycell {
		display: none;
		@include mq("md") {
			display: block;
		}
	}
	&___heading {
		font-weight: var(--font-weight--bold);
		color: var(--color-primary-gray-90);
	}
}

.CostDetails_headingWrapper___heading {
	text-align: right;
	grid-column-end: 3;
	grid-column-start: 1;
}

// Details with data
.CostDetails_wrapper {
	@include mq("md") {
		width: 300px;
		margin: auto var(--spacing--2xl);
		align-self: flex-end;
	}
}
.CostDetails_section {
	padding: var(--spacing--sm) 0;

	&___top {
		padding: var(--spacing--xl) 0 var(--spacing--md);
	}
	&___ending {
		padding: var(--spacing--xs) 0 var(--spacing--lg);
	}
}

.CostDetails_data {
	display: grid;
	grid-template-columns: 40% auto;

	&___label {
		color: var(--color-primary-gray-50);
	}
	&___labelGrand {
		font-weight: var(--font-weight--bold);
		color: var(--color-primary-dark-blue);
	}

	&___price {
		font-weight: var(--font-weight--bold);
		display: flex;
		justify-content: flex-end;
	}
}

// Grand total cell outside of CostDetails_wrapper
.CostDetails_data___grandTotalWrapper {
	padding: var(--spacing--lg) 0;
	border-top: 1px dashed var(--color-primary-gray-50);

	@include mq("md") {
		display: flex;
		justify-content: flex-end;
	}
}
.CostDetails_data___grandTotalCell {
	@include mq("md") {
		width: 300px;
		margin: auto var(--spacing--2xl);
	}
}

.CostDetails_proceed {
	display: flex;
	justify-content: flex-end;
	margin: var(--spacing--xl) 0;
}

.CostDetails_proceedButton {
	width: 100%;

	&___iconRight {
		margin-left: calc(var(--spacing--sm) * 1.5);
	}

	@include mq("md") {
		width: unset;
	}
}
