@import "ui/styles/2-tools/tools.media-query.scss";

.ShippingDate {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing--sm);
	padding: var(--spacing--xl) 0;

	@include mq("md", "max") {
		> :nth-of-type(3) {
			flex: 0 0 100%;
		}
	}

	@include mq("md") {
		justify-content: end;
		flex-wrap: nowrap;
	}
}

.ShippingDate_text {
	font-weight: var(--font-weight--bold);
}

.ShippingDate_centerAlignmentBox {
	display: grid;
	align-items: center;
}
