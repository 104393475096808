@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";

.FormLabel {
	@include paragraph--md;
	display: flex;
	width: 100%;
	padding: var(--spacing--2xs) 0;
	font-weight: var(--font-weight--bold);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-primary-gray-60);
		cursor: auto;
	}

	&.isHidden {
		display: none;
	}

	&.hasTextNoWrap {
		white-space: nowrap;
	}

	/* &.isRequired {
		color: var(--color-error);
		margin-left: var(--spacing--2xs);
	} */
}
