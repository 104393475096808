// TODO: SHOULD BE REMOVED AND REPLACED BY STYLING IN MODULE

$spacings: (
	none: 0,
	xxs: var(--spacing--2xs),
	xs: var(--spacing--xs),
	sm: var(--spacing--sm),
	md: var(--spacing--md),
	lg: var(--spacing--lg),
	xl: var(--spacing--xl),
	xxl: var(--spacing--2xl),
	xxxl: var(--spacing--3xl),
);

@each $name, $value in $spacings {
	.u-padding--#{$name} {
		padding: $value !important;
	}

	.u-bottom-margin--#{$name} {
		margin-bottom: $value !important;
	}

	.u-bottom-padding--#{$name} {
		padding-bottom: $value !important;
	}

	.u-top-margin--#{$name} {
		margin-top: $value !important;
	}

	.u-top-padding--#{$name} {
		padding-top: $value !important;
	}

	.u-left-padding--#{$name} {
		padding-left: $value !important;
	}

	.u-left-margin--#{$name} {
		margin-left: $value !important;
	}

	.u-right-padding--#{$name} {
		padding-right: $value !important;
	}

	.u-right-margin--#{$name} {
		margin-right: $value !important;
	}
} ;
