@import "ui/styles/2-tools/tools.action";

.LinkButton {
	@include button;

	&___sm {
		font-size: var(--font-size--sm);
	}

	&:hover {
		text-decoration: underline;
	}
}

.LinkButton___hasIcon {
	display: flex;
	align-items: center;
	gap: var(--spacing--xs);
}

.LinkButton_buttonIcon {
	min-width: 1rem;
	width: 1rem;
	height: 1rem;

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	&___back {
		transform: rotate(-90deg);
	}

	&___front {
		transform: rotate(90deg);
	}
}