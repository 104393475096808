@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.Avatar_button {
	min-width: auto;
	font-size: var(--font-size--xs);
	border: none;
	text-overflow: ellipsis;
	overflow: hidden;

	@include mq("md", "max") {
		font-size: var(--font-size--md);
	}

	&___loginButton {
		border: 1px solid var(--color-primary-gray-40);

		@include mq("md", "max") {
			padding: var(--spacing--sm) var(--spacing--lg);
		}
	}

	&___logoutButton {
		margin-top: var(--spacing--md);
		border: 1px solid var(--color-primary-gray-40);
		@include mq("md") {
			display: none;
		}
	}

	&:hover,
	&:focus {
		color: var(--color-primary-gray-90);
		background: var(--color-white);
	}

	.isOpen & {
		color: var(--color-white);
		background: var(--color-primary-dark-blue);
	}
}

.Avatar_container {
	position: relative;
	height: 100%;
	width: 180px;
	border-radius: var(--corner-size--sm);
	border: 1px solid var(--color-primary-gray-40);

	@include mq("md") {
		width: 150px;
	}

	&.isOpen {
		border-radius: var(--corner-size--sm) var(--corner-size--sm) 0 0;
		/* border-bottom: none; */
	}
}

.Avatar_toggle {
	width: 100%;
	position: relative;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: var(--spacing--xs) var(--spacing--lg) var(--spacing--xs)
		var(--spacing--md);
	text-align: left;
}

.Avatar_dropdownContainer {
	position: absolute;
	background-color: var(--color-white);
	left: -1px;
	border-radius: 0 0 var(--corner-size--sm) var(--corner-size--sm);

	> :first-child {
		border-radius: 0;
	}

	> :last-child {
		border-radius: 0 0 var(--corner-size--sm) var(--corner-size--sm);
		border: 1px solid var(--color-primary-gray-40);
	}
}

.Avatar_wrapper {
	position: relative;
	display: none;
	border-left: 1px solid var(--color-primary-gray-40);
	border-right: 1px solid var(--color-primary-gray-40);

	width: 180px;
	@include mq("md") {
		width: 150px;
	}

	&.isOpen {
		display: flex;
		flex-direction: column;
		z-index: 2;
	}
}

.Avatar_dropdownButton {
	width: 100%;

	&:hover,
	&:focus {
		color: var(--color-primary-gray-90);
		background: var(--color-white);
	}
}

.Avatar_buttonIcon {
	width: 1rem;
	height: 1rem;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);

	@include transition {
		transition: all var(--trans-time--long);
	}

	.isOpen & {
		transform: translateY(-50%) rotate(180deg);
		
		& svg {
			fill: var(--color-white);
		}
	}
}

@include mq("md", "max") {
	.Avatar_dropdownContainer {
		> :last-child {
			display: none;
		}

		> :nth-last-child(2) {
			border-bottom: 1px solid var(--color-primary-gray-40);
			border-radius: 0 0 var(--corner-size--sm) var(--corner-size--sm);
		}
	}
}
