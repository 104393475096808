/* ------------------------------------ *\
		settings.spacing
\* ------------------------------------ */

:root {
	--spacing--base: 1.6rem;

	--spacing--2xs: calc(var(--spacing--base) / 8);
	--spacing--xs: calc(var(--spacing--base) / 4);
	--spacing--sm: calc(var(--spacing--base) / 2);
	--spacing--md: calc(var(--spacing--base) * 1);
	--spacing--lg: calc(var(--spacing--base) * 1.5);
	--spacing--xl: calc(var(--spacing--base) * 2);
	--spacing--2xl: calc(var(--spacing--base) * 2.5);
	--spacing--3xl: calc(var(--spacing--base) * 3);
	--spacing--4xl: calc(var(--spacing--base) * 3.5);
	--spacing--5xl: calc(var(--spacing--base) * 4);
	--spacing--6xl: calc(var(--spacing--base) * 4.5);
	--spacing--7xl: calc(var(--spacing--base) * 5);
}
