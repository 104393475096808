.CheckoutForm {
	width: 100%;
	max-width: 42rem;
}

.CheckoutForm_heading {
	margin-bottom: var(--spacing--md);
	text-align: center;
}

.CheckoutForm_image {
	width: 80px;
	margin-bottom: var(--spacing--xl);
}

.CheckoutForm_payment {
	width: 100%;
	padding-bottom: var(--spacing--md);
	margin-bottom: var(--spacing--md);
	border-bottom: 1px solid var(--color-primary-sand);
}

.CheckoutForm_button {
	width: 100%;
	min-height: 4rem;
}

.CheckoutForm_paymentMessage {
	margin-top: var(--spacing--sm);
}