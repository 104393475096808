@import "ui/styles/2-tools/tools.media-query.scss";

.FooterAddressCard_selectContainer {
	position: relative;
	display: flex;
	select {
		margin-left: -5px;
		color: var(--color-primary-dark-blue);
	}
}

.FooterAddressCard_heading {
	text-transform: uppercase;
	margin-bottom: 1em;
	margin-top: 2em;
	text-align: left;
	font-weight: var(--font-weight--bold);

	@include mq("md") {
		margin-top: 0;
	}
}

.FooterAddressCard_select {
	padding-right: var(--spacing--xs);

	select {
		font-size: var(--font-size--sm);
		text-transform: uppercase;
		font-weight: var(--font-weight--semibold);
		width: 100%;
		padding: 0;
		background: none;
		border: none;
		outline: none;

		&:focus,
		&:hover {
			background-color: initial;
			outline: none;
		}
	}
}

.FooterAddressCard_actionsWrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: var(--spacing--md) 0;
	> ul {
		margin: 0 0 0 var(--spacing--lg);
		align-items: center;
	}
	@include mq("md") {
		> ul {
			display: none;
		}
	}
	.FooterAddressCard_showSocialLinksFullWidth {
		display: flex;
	}
}
